import SectionDisplay from '../testpage/SectionDisplay';
import '../css/app.css'
import '../css/bootstrap45.min.css'
import '../css/simplebar.css'

function Footer({
    user, 
    isMinimapVisible, 
    minimapRef, 
    currentExam, 
    currentQuestionIndex, 
    isQuestionAnswered, 
    toggleMinimap, 
    handleSelectQuestion, 
    openReviewPage, 
    handlePreviousQuestion, 
    handleNextQuestion,
    showReviewPage,
    setMiniMapFalse,
    isMarkedReview,
  }) {
    // console.log('printing show revew,',currentExam)
    return (
      <div className="footer">
        <div className="name-zone btn-group dropup">
          <button type="button" className="btn-person" id="user-button" data-toggle="dropdown" aria-expanded="false">
            <i className="bi-person-fill" style={{ fontSize: '1.3rem' }}></i> {user.first_name} {user.last_name}
          </button>
          <div className="dropdown-menu usermap"></div>
        </div>
        {isMinimapVisible && !showReviewPage && (<div ref={minimapRef} className="minimap">
        <div className="contents">
          <div className="section-info">
            <SectionDisplay order={currentExam?.name} elementType="span" className="section-info-title" />
            <span id="close-qlist" className="bi-x-lg" onClick={toggleMinimap}></span>
          </div>
          <div className="icon-detail">
            <div className="single-detail"><i className="bi bi-geo-alt" style={{ fontSize: '1.5rem' }}></i><span>Current</span></div>
            <div className="single-detail"><div className="dashed-square"></div><span>Unanswered</span></div> 
            <div className="single-detail"><i className="bi-bookmark-fill"></i><span>For Review</span></div>
          </div>
          <div className="question-list">
        {currentExam?.questions.map((question, index) => (
          <div
  key={index}
  className={`single-question ${
    currentQuestionIndex === index ? "current" : ""
  } ${isQuestionAnswered(question.id) ? "answered" : ""} `}
>            
            {currentQuestionIndex === index && (
              <i className="bi bi-geo-alt current-position" style={{ fontSize: '1.5rem' }}></i>
            )}
            
            <div 
              className={`question-num ${isQuestionAnswered(question.id) ? 'answered' : ''}`} 
              style={{ textDecoration: currentQuestionIndex === index ? 'underline' : 'none' }}
              onClick={() => {handleSelectQuestion(index);
                setMiniMapFalse();}}
              
            >
              {index + 1}
            </div>
            {isMarkedReview(question.id) && (
    <div className="marked">
      <i className="bi bi-bookmark-fill"></i>
    </div>
  )}
          </div>
        ))}
</div>
          {/* <div className="section-pageview">
            <button type="button" onClick={()=>{openReviewPage();
            setMiniMapFalse();
            }} className="ctrl-btn ctrl-pageview">
              Go to Review Page
            </button>
            
          </div> */}
        </div>
      </div>)}
        {!showReviewPage && (<div id="qlist-button" className="question-info" onClick={toggleMinimap}>
          <span>Question {currentQuestionIndex + 1} of {currentExam?.questions.length}</span>
          <div className={`arrow-up ${isMinimapVisible ? 'down' : ''}`}></div>
        </div>)}
        <div className="ctrl-btns-container">
          {(currentQuestionIndex > 0 ||showReviewPage ) && (
            <button type="button" onClick={handlePreviousQuestion} className="ctrl-btn">
              Back
            </button>
          )}
          <button type="button" onClick={handleNextQuestion} className="ctrl-btn">
            Next
          </button>
        </div>
      </div>
    );
  }
  
  export default Footer;