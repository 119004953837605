// DesmosCalculator.js
// import React, { useEffect, useRef } from 'react'

// const DesmosCalculator = () => {
// 	const calculatorRef = useRef(null)

// 	useEffect(() => {
// 		const calculator = window.Desmos.GraphingCalculator(
// 			calculatorRef.current
// 		)
// 		return () => calculator.destroy()
// 	}, [])

// 	return (
// 		<div style={{ height: '100%', position: 'relative' }}>
// 			<div ref={calculatorRef} style={{ height: '100%' }} />
// 		</div>
// 	)
// }

// export default DesmosCalculator

import React, { useEffect, useRef,forwardRef } from 'react'

const DesmosCalculator = forwardRef((props, ref) => {
	const calculatorRef = useRef(null)

	useEffect(() => {
		const calculator = window.Desmos.GraphingCalculator(
			calculatorRef.current
		)
		return () => calculator.destroy()
	}, [])

	return (
		<div ref={ref} style={{ height: '100%', position: 'relative' }}>
			<div ref={calculatorRef} style={{ height: '100%' }} />
		</div>
	)
});

export default DesmosCalculator

