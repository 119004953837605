import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  IconButton
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import { editImgPercentage } from '../actions/auth';
import { useDispatch, useSelector } from 'react-redux';

//editImgPercentage

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: 'black',
  color: 'white'
});
const StyledDialogActions = styled(DialogActions)({
  backgroundColor: '#e0e0e0',
  padding: '10px 24px'
});
const StyledDialogContent = styled(DialogContent)({
  marginTop: '8px',
  paddingTop: '20px',
  paddingBottom: '20px',
  backgroundColor: '#f5f5f5'
});

// Custom Image Handler for Quill
const resizeImages = (content, imgPercentage) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(content, 'text/html');
  const images = doc.querySelectorAll('img');
  const widthPercentage = imgPercentage ? imgPercentage : 100;
  images.forEach(img => {
    img.style.width = `${widthPercentage}%`;
    img.style.height = 'auto';
  });
  return doc.body.innerHTML;
};

// Define the component
const CreateQuestionDialog = ({
  isCreateQuestionOpen,
  closeCreateQuestionModal,
  newQuestion,
  setNewQuestion,
  richTextContentQuestionText,
  handleRichTextChangeQuestionText,
  richTextContentDescription,
  handleRichTextChangeDescription,
  richTextChoices,
  handleChoiceChange,
  handleCorrectChoiceChange,
  handleCreateQuestion,
  user,
}) => {
  const [isEditingScale, setIsEditingScale] = useState(false);
  const [imageScale, setImageScale] = useState(user?.img_percentage || 100);

  const choiceToLetter = (index) => ['A', 'B', 'C', 'D'][index];

  const handleQuestionTextChange = (content) => {
    const resizedContent = resizeImages(content, imageScale);
    handleRichTextChangeQuestionText(resizedContent);
  };

  const handleDescriptionChange = (content) => {
    const resizedContent = resizeImages(content, imageScale);
    handleRichTextChangeDescription(resizedContent);
  };

  const handleChoiceTextChange = (index) => (content) => {
    const resizedContent = resizeImages(content, imageScale);
    handleChoiceChange(index)(resizedContent);
  };

  const handleEditScaleClick = () => {
    setIsEditingScale(true);
  };

  const handleSaveScaleClick = async () => {
    try {
      const response = await editImgPercentage(user.id,imageScale);

    } catch (error) {
    }
    setIsEditingScale(false);

  };

  const handleCancel =() => {
    setIsEditingScale(false )
    closeCreateQuestionModal()
  }

  return (
    <Dialog open={isCreateQuestionOpen} onClose={closeCreateQuestionModal} fullWidth maxWidth="md">
      <StyledDialogTitle>Create a New Question</StyledDialogTitle>
      <StyledDialogContent>
      <Box display="flex" alignItems="center" mb={2}>
  <Typography variant="body1" component="div" display="flex" alignItems="center">
    Image Scale: 
    {isEditingScale ? (
      <>
        <TextField
          value={imageScale}
          onChange={(e) => setImageScale(e.target.value)}
          type="number"
          inputProps={{ min: 1, max: 100 }}
          style={{ width: '70px', marginLeft: '8px', marginRight: '8px' }}
          size="small"
        />
        <span style={{ marginRight: '8px' }}>%</span>
        <Button color="primary" onClick={handleSaveScaleClick} size="small" variant="contained" style={{ marginRight: '8px' }}>
          Save
        </Button>
        <Button color="secondary" onClick={() => setIsEditingScale(false)} size="small" variant="contained">
          Cancel
        </Button>
      </>
    ) : (
      <span style={{ marginLeft: '8px', marginRight: '8px' }}>{`${imageScale}%`}</span>
    )}
    {!isEditingScale && (
      <IconButton color="primary" onClick={handleEditScaleClick} size="small">
        <EditOutlinedIcon />
      </IconButton>
    )}
    <Tooltip title="This sets the scale of images inserted in the question text, description, and choices, because everyone's laptop's screen size and resolution is different">
      <IconButton size="small">
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  </Typography>
</Box>


        <strong>Question:</strong>
        <ReactQuill
          value={richTextContentQuestionText}
          onChange={handleQuestionTextChange}
          placeholder="Enter the question text..."
          theme="snow"
          style={{ height: '20vh', marginBottom: '4rem' }}
        />
        <strong>Description:</strong>
        <ReactQuill
          value={richTextContentDescription}
          onChange={handleDescriptionChange}
          placeholder="Enter the question description (optional)..."
          theme="snow"
          style={{ height: '20vh', marginBottom: '4rem' }}
        />

        <FormControl fullWidth>
          <InputLabel>Question Type</InputLabel>
          <Select
            value={newQuestion.question_type}
            onChange={(e) => setNewQuestion({ ...newQuestion, question_type: e.target.value })}
          >
            <MenuItem value="MC">Multiple Choice</MenuItem>
            <MenuItem value="NI">Numeric Input</MenuItem>
          </Select>
        </FormControl>

        {newQuestion.question_type === 'MC' && (
          <Box>
            {richTextChoices.map((choice, index) => (
              <Box key={`choice-${index}`} mb={5}>
                <Typography variant="h6" gutterBottom>
                  Choice {choiceToLetter(index)}
                </Typography>
                <ReactQuill
                  value={choice}
                  onChange={handleChoiceTextChange(index)}
                  theme="snow"
                  style={{ height: '100px' }}
                />
                {index < richTextChoices.length - 1 && <hr />} {/* Divider */}
              </Box>
            ))}
            <FormControl fullWidth margin="normal">
              <InputLabel>Correct Choice</InputLabel>
              <Select
                value={newQuestion.correct_choice}
                onChange={handleCorrectChoiceChange}
              >
                {richTextChoices.map((_, index) => (
                  <MenuItem key={index} value={index}>
                    Choice {choiceToLetter(index)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )}

        {newQuestion.question_type === 'NI' && (
          <TextField
            label="Correct Numerical Answer"
            variant="outlined"
            fullWidth
            type="number"
            value={newQuestion.correct_numerical_answer}
            onChange={(e) => setNewQuestion({ ...newQuestion, correct_numerical_answer: e.target.value })}
            margin="normal"
          />
        )}
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreateQuestion} color="primary" variant="contained">
          Create
        </Button>
      </StyledDialogActions>
    </Dialog>
  );
};

// Export the component
export default CreateQuestionDialog;
