import React, { useEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MyExams from '../../components/MyExams';
import { fetchScheduledExams } from '../../actions/auth';
import { Grid, Typography, Box, Container } from '@mui/material';
import Header from '../../components/Header';
import MyPastExam from '../../components/MyPastExam';
import LoadingOverlay from '../../components/LoadingOverlay';
const ExamList = () => {
    const dispatch = useDispatch();
    const examsData = useSelector((state) => state.scheduledExams); // Adjust according to your state structure
    const [loading, setLoading] = useState(true); // Initialize loading state
    
    useEffect(() => {
        dispatch(fetchScheduledExams())
            .then(() => setLoading(false)) // Set loading to false once data is fetched
            .catch((error) => {
                console.error('Failed to fetch exams:', error);
                setLoading(false); // Also set loading to false in case of error
            });
    }, [dispatch]);
    // console.log(examsData)

    // Function to sort exams in descending order by start time
    const sortExams = (exams) => {
        return exams.sort((a, b) => new Date(b.exam_schedule.start_time) - new Date(a.exam_schedule.start_time));
    };

    const MemoizedSection = React.memo(({ title, filteredExams }) => (
        filteredExams?.length > 0 ? (
            <Box my={4}>
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{title}</Typography>
                <Grid container spacing={3}>
                    {sortExams(filteredExams).map((exam) => (
                        <MyExams key={exam.id} exam={exam} />
                    ))}
                </Grid>
            </Box>
        ) : null
    ));


    const MemoizedSectionPast = React.memo(({ title, filteredExams }) => (
        filteredExams?.length > 0 ? (
            <Box my={4}>
                <Typography variant="h3" sx={{ fontWeight: 'bold' }}>{title}</Typography>
                <Grid container spacing={3}>
                    {sortExams(filteredExams).map((exam) => (
                        <MyPastExam key={exam.id} exam={exam} />
                    ))}
                </Grid>
            </Box>
        ) : null
    ));
    if (loading) {
        return (

<LoadingOverlay/>
        );
    }
    const hasExams = examsData.scheduled.current_exams?.length > 0 || examsData.scheduled.future_exams?.length > 0 || examsData.scheduled.past_exams?.length > 0;

    return (
        <Container sx={{ margin: '20px' }}>
<Header title="MY EXAMS" subtitle={`Total Scheduled Exams: ${(examsData.scheduled.future_exams?.length + examsData.scheduled.current_exams?.length) || 0}`} subtitle2 = {`Total Completed Exams: ${examsData.scheduled.past_exams?.length || 0}`} page="myExams"/>

            {hasExams ? (
                <>
                    <MemoizedSection title="Current Exams" filteredExams={examsData.scheduled.current_exams || []} />
                    <MemoizedSection title="Future Exams" filteredExams={examsData.scheduled.future_exams || []} />
                    <MemoizedSectionPast title="Past Exams" filteredExams={examsData.scheduled.past_exams || []} />
                </>
            ) : (
                <Box textAlign="center" my={5}>
                    <Typography variant="h5">Looks like there are no exams scheduled yet!</Typography>
                    <Typography variant="subtitle1">Why not take this time to relax✨</Typography>
                </Box>
            )}
        </Container>
    );
};

export default ExamList;


