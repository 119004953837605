import React from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import TopHeader from '../scenes/testpage/TopHeader';
import Footer from '../scenes/testpage/Footer';
import GpsFixedIcon from '@mui/icons-material/GpsFixedOutlined';
import styles from '../scenes/css/ReviewPageComponent.module.css';
import '../scenes/css/app.css'; // This contains global styles

const getSectionName = (order) => {
  const sectionNames = {
    'Reading and Writing 1': 'Section 1, Module 1: Reading and Writing',
    'Reading and Writing 2': 'Section 1, Module 2: Reading and Writing',
    'Math 1': 'Section 2, Module 1: Math',
    'Math 2': 'Section 2, Module 2: Math',
  };

  return sectionNames[order] || 'Unknown Section';
};
const ReviewPageComponent = ({ onClose, endTime,questions, onSelectQuestion, currentQuestionIndex, handleModuleTransition, currentExam, remainingTime=0, handleTimeout, user, isMinimapVisible, minimapRef, isQuestionAnswered, toggleMinimap, handleSelectQuestion, openReviewPage, handlePreviousQuestion, handleNextQuestion, showReviewPage ,order,isMarkedReview}) => {
  // console.log('end time',endTime)
  return (
    <div className={`${styles.wrapper} wrapper`}> {/* Using both local and global `wrapper` class */}
      <TopHeader
        currentExam={currentExam}
        endTime = {endTime}
        handleTimeout={handleTimeout}
      />
      <div className={`${styles['page-wrapper']} page-wrapper`} id="sandbox"> {/* Same approach for `page-wrapper` */}
        <div className={`${styles['my-wrapper']} my-wrapper`} data-simplebar>
          <div className={`${styles.mycontainer} mycontainer`}>
            <span className={`${styles.title} title`}>Check Your Work</span>
            <span className={`${styles.desc} desc`}>On test day, you won't be able to move on to the next module until time expires.</span>
            <span className={`${styles.desc} desc`}>For this practice questions, you can click <b>Next</b> when you're ready to move on.</span>
            <div className={`${styles.minimap2} minimap2`}>
              <div className={`${styles.contents} contents`}>
                <div className={`${styles['section-info']} section-info`}>
                  <span className={`${styles['section-title']} section-title`}>{getSectionName(order)}</span>
                  <div className={`${styles['icon-detail2']} icon-detail2`}>
                    <div className={`${styles['single-detail']} single-detail`}><div className={`${styles['dashed-square']} dashed-square`}></div><span>Unanswered</span></div>
                    <div className={`${styles['single-detail']} single-detail`}><i className="bi-bookmark-fill"></i><span>For Review</span></div>
                  </div>
                </div>
                <div className={`${styles['question-list']} question-list`}>
                  {currentExam?.current_module?.questions.map((question, index) => (
                    
                    <div key={index} className={`${styles['single-question']} single-question ${currentQuestionIndex === index ? styles.current : ''} ${isQuestionAnswered(question.id) ? styles.answered : ''}`}>
{isMarkedReview(question.id) && (
    <div className={`${styles.marked} marked`}>
      <i className="bi bi-bookmark-fill"></i>
    </div>
  )}
                      <div 

                        className={`${styles['question-num']} question-num ${isQuestionAnswered(question.id) ? 'answered' : ''}`}
                        onClick={() => {
                          handleSelectQuestion(index);
                          onClose();
                        }}
                      >
                        {index + 1}
                      </div>

                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        user={user}
        isMinimapVisible={isMinimapVisible}
        minimapRef={minimapRef}
        currentExam={currentExam}
        currentQuestionIndex={currentQuestionIndex}
        isQuestionAnswered={isQuestionAnswered}
        toggleMinimap={toggleMinimap}
        handleSelectQuestion={handleSelectQuestion}
        openReviewPage={openReviewPage}
        handlePreviousQuestion={onClose}
        handleNextQuestion={handleModuleTransition}
        showReviewPage={showReviewPage}
      />
    </div>
  );
};

export default ReviewPageComponent;
