import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';



const LoadingOverlay = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    position="fixed"
    top={0}
    left={0}
    width="100%"
    height="100%"
    className="bg-black bg-opacity-50"  // Tailwind classes for background
    flexDirection="column"
    zIndex={1500}
  >
<CircularProgress color="inherit" aria-label="Loading content" />

    <Typography
      variant="caption"
      className="text-gray-600 mt-1 text-sm"  // Tailwind classes for the designer credit
    >
      Designed by Jimmy Miao
    </Typography>
  </Box>
);

export default LoadingOverlay;
