import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useDispatch } from 'react-redux';
import { createExam } from '../actions/auth';
import { useNavigate } from 'react-router-dom';

export default function CreateExamDialog(props) {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [examType, setExamType] = useState('SAT');
    const EXAM_TYPES = ['SAT'];
    // const EXAM_TYPES = ['SAT', 'ACT', 'PSAT'];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await dispatch(createExam(name, description, examType));
            console.log('starts');
            console.log(response);
            if (response && response.data && response.data.id) {
                props.onClose();
                console.log("going to redirect");
                navigate(`/viewExamDetails/${response.data.id}`);
            }
            console.log('done');
        } catch (error) {
            // Handle error
        }
    };

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>Create a New Exam</DialogTitle>
            <DialogContent>
                <form onSubmit={handleSubmit} id="createExamForm">
                    <TextField
                        label="Exam Name"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <TextField
                        label="Source"
                        fullWidth
                        margin="normal"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Exam Type</InputLabel>
                        <Select
                            value={examType}
                            onChange={(e) => setExamType(e.target.value)}
                            required
                        >
                            {EXAM_TYPES.map((type) => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
                <Button type="submit" form="createExamForm" color="primary">Add</Button>
            </DialogActions>
        </Dialog>
    );
}
