import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password_confirm } from '../../actions/auth';
import { Button, Box, CircularProgress, Snackbar, Alert } from '@mui/material';
import br from '../resetpassword/logo.svg';
import LoadingOverlay from '../../components/LoadingOverlay';
const ResetPasswordConfirm = ({ reset_password_confirm }) => {
    const [formData, setFormData] = useState({
        new_password: '',
        re_new_password: ''
    });
    const { uid, token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    
    const { new_password, re_new_password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async (e) => {
        e.preventDefault();
        
        // Check if passwords match
        if (new_password !== re_new_password) {
            setError('Passwords do not match.');
            setOpenSnackbar(true);
            return; // Stop the form submission
        }
        
        setLoading(true); // Start loading
        const result = await reset_password_confirm(uid, token, new_password, re_new_password);
        // console.log(result)
        if (!result.success) {
            // Handling the case where new_password errors are returned as an array
            const newPasswordErrors = result.error.new_password;
            if (newPasswordErrors && newPasswordErrors.length > 0) {
                setError(newPasswordErrors.join(', ')); // Joining array elements into a single string
            } else if (result.error.token) {
                setError('The reset password link is already expired'); // Generic error message
            } else {
                setError('Failed to reset password. Please try again.'); // Generic error message

            }
            setOpenSnackbar(true);
        } else {
            // Navigate on success
            setLoading(false); // Start loading
            navigate('/login', { state: { resetEmailConfirm: true } });
        }
    
        setLoading(false); // End loading
    };
    

    
    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    if (loading) {
        return (

<LoadingOverlay/>
        );
    }


    return (
        <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-8 h-8 mr-2" src={br} alt="logo" />
          LIGHTEN ACADEMY
        </a>
        <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
          <h2 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            Change Password
          </h2>
          <form className="mt-4 space-y-4 lg:mt-5 md:space-y-5" action="#" onSubmit={onSubmit}>

            <div>
              <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">New Password</label>
              <input
    type="password"
    name="new_password" // This should match the key in your formData state
    id="password"
    placeholder="••••••••"
    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    required
    value={new_password}
    onChange={onChange}
/>
            </div>
            <div>
              <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
              <input
    type="password"
    name="re_new_password" // This should also match the key in your formData state
    id="confirm-password"
    placeholder="••••••••"
    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    required
    value={re_new_password}
    onChange={onChange}
/>
            </div>

            <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                width: '100%',
                                padding: '10px 0', // Increase padding for higher height
                                marginBottom: '8px', // Add some margin below the button
                                backgroundColor: 'primary.main',
                                '&:hover': {
                                    backgroundColor: 'primary.dark',
                                },
                            }}
                        >
                            Reset password
                        </Button>           </form>
        </div>
      </div>
        
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                {error}
            </Alert>
        </Snackbar>
    </section>
        
    );
};

export default connect(null, { reset_password_confirm })(ResetPasswordConfirm);
