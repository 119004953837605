import React, { useState, useEffect } from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { listscheduleExam, deleteScheduleExam } from '../../actions/auth';

import {
  Box, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText, Container, Snackbar
} from '@mui/material';
import Header from '../../components/Header';

const Calendar = () => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [eventDialogOpen, setEventDialogOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const fetchSchedules = async () => {
      try {
        const scheduleData = await listscheduleExam();
        const calendarEvents = scheduleData.map(schedule => ({
          id: schedule.id,
          title: schedule.exam_name,
          start: schedule.start_time,
          end: schedule.end_time,
          extendedProps: schedule
        }));
        setCurrentEvents(calendarEvents);
      } catch (error) {
        console.error('Error fetching schedules:', error);
      }
    };
    fetchSchedules();
  }, []);

  const handleDeleteEvent = async () => {
    if (selectedEvent) {
      try {
        const response = await deleteScheduleExam(selectedEvent.id);
        if (response === 204) {
          const updatedEvents = currentEvents.filter(event => event.id !== selectedEvent.id);
          setCurrentEvents(updatedEvents);
          setSnackbarMessage('Schedule deleted successfully');
          setSnackbarOpen(true);
        } else {
          setSnackbarMessage('Error deleting schedule');
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error('Failed to delete schedule:', error);
        setSnackbarMessage('Failed to delete schedule');
        setSnackbarOpen(true);
      }
      setEventDialogOpen(false);
    }
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event.extendedProps);
    setEventDialogOpen(true);
  };

  const isTodayOrTomorrow = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const eventDate = new Date(date);
    return eventDate.toDateString() === today.toDateString() || eventDate.toDateString() === tomorrow.toDateString();
  };

  return (
    <Container sx={{ margin: '20px' }}>
      <Header title="Digital SAT Schedule" subtitle="All users are able to access this page" />
      <Box display="flex" justifyContent="space-between">
        {/* Today's and Tomorrow's Schedules */}
        <Box flex="1" p="15px">
          <Typography variant="h5">Today's and Tomorrow's Schedules</Typography>
          <List>
            {currentEvents.filter(event => isTodayOrTomorrow(event.start)).map(event => (
              <ListItem key={event.id} button onClick={() => setSelectedEvent(event.extendedProps)}>
                <ListItemText
                  primary={event.title}
                  secondary={formatDate(event.start, { year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit" })}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        {/* Calendar */}
        <Box flex="3" p="15px">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,dayGridMonth"
            }}
            hiddenDays={[0, 6]}
            events={currentEvents}
            eventClick={handleEventClick}
            nowIndicator={true}
          />
        </Box>
      </Box>
      {/* Event Details Dialog */}
      <Dialog open={eventDialogOpen} onClose={() => setEventDialogOpen(false)}>
        <DialogTitle>Exam Details</DialogTitle>
        {selectedEvent && (
          <DialogContent>
            <Typography variant="h6">Exam: {selectedEvent.exam_name}</Typography>
            <Typography>Type: {selectedEvent.exam_type}</Typography>
            <Typography>Start: {formatDate(selectedEvent.start_time, { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" })}</Typography>
            <Typography>End: {formatDate(selectedEvent.end_time, { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit" })}</Typography>
            <Typography>Students:</Typography>
            {selectedEvent.students_for_read.map(student => (
              <Typography key={student.id}>- {student.name}</Typography>
            ))}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleDeleteEvent} color="secondary">Delete Event</Button>
          <Button onClick={() => setEventDialogOpen(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Container>
  );
};

export default Calendar;
