import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Switch,
  List,
  CircularProgress,
  Paper,
  Stepper,
  Step,
  StepButton,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,TextField,
  DialogActions,Breadcrumbs,Box,Link as MuiLink,Snackbar,Alert,
  MenuItem,DialogContentText,Container
} from '@mui/material';
import LoadingOverlay from '../../components/LoadingOverlay';
import CreateQuestionDialog from '../../components/CreateQuestionDialog';
import EditQuestionDialog from '../../components/EditQuestionDialog';
import { renderChoicesInGrid } from './renderChoicesInGrid';
import { updateQuestionOrder,updateExamName } from '../../actions/auth';
import OldHeader from '../../components/oldHeader';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Import the icon
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchExams,
  setPublishStatus,
  createQuestion,
  deleteQuestion,deleteExamAPI,editQuestion
} from '../../actions/auth';
// import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import DOMPurify from 'dompurify';
import "./style.css"

{/* <IconButton
color="error"
aria-label="delete exam"
onClick={openDeleteExamConfirmDialog}
style={{ padding: '10px' }} // Optional: Adjust styling as needed
>
<DeleteOutlinedIcon />
</IconButton> */}
const ViewExamDetails = () => {
  const { examId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const exam = useSelector((state) => state.scheduledExams.singleexam);
  // console.log('exam',exam);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isPublished, setIsPublished] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success');
const [newExamName, setNewExamName] = useState('');
const [isEditingName, setIsEditingName] = useState(false);

const handleSnackbarOpen = (message, severity = 'success') => {
  setSnackbarMessage(message);
  setSnackbarSeverity(severity);
  setSnackbarOpen(true);
};

const handleSnackbarClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }
  setSnackbarOpen(false);
};

const handleSaveEditQuestion = (editedData) => {
  const { id, ...updateData } = editedData;

  dispatch(editQuestion(updateData, id))
    .then(() => {
      setIsEditQuestionOpen(false); // Close the dialog
      dispatch(fetchExams(examId));
      handleSnackbarOpen('Save the edited question successfully', 'success');
    })
    .catch((err) => {
      if (err.response && err.response.status === 403) {
        // Handle 403 Forbidden error
        handleSnackbarOpen('Failed to save the edited question, no permission', 'error');
      } else {
        // Handle other errors
        handleSnackbarOpen('Failed to save the edited question', 'error');
      }
    });
};

  

  
  const [newQuestion, setNewQuestion] = useState({
    text: '',
    description: '',
    question_type: 'MC',
    order: 0,
    correct_choice: 0,
    correct_numerical_answer: 0,
    module: 0,
  });
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [deletingQuestionId, setDeletingQuestionId] = useState(null);
  
  const [isCreateQuestionOpen, setCreateQuestionOpen] = useState(false);
  const [isEditQuestionOpen, setIsEditQuestionOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState(null);

  const [richTextContentQuestionText, setRichTextContentQuestionText] = useState('');

  const [richTextContentDescription, setRichTextContentDescription] = useState('');
  const [richTextChoices, setRichTextChoices] = useState(['', '', '', '']);
  const [isDeleteExamConfirmOpen, setIsDeleteExamConfirmOpen] = useState(false);
  const user = useSelector(state => state.auth.user);
  // console.log(user.group_names[0]);
  const openEditQuestionDialog = (question) => {
    // console.log('question',question)
    setEditingQuestion(question);
    setRichTextContentQuestionText(question.text || '');
    setRichTextContentDescription(question.description || '');
    setRichTextChoices(question.choices || []);
    setIsEditQuestionOpen(true);
  };
  
  
  const openCreateQuestionModal = () => {
    resetQuestionForm();  // Reset the form and close the modal

    setCreateQuestionOpen(true);

  };
  

  // Function to close the create question modal
  useEffect(() => {
    // console.log('using effect');
    if (exam.is_published !== null) {
      setIsPublished(exam.is_published);
    }
  }, [exam.is_published]);

  useEffect(() => {
    if (exam.modules && exam.modules.length > 0) {
      setNewQuestion(prevState => ({
        ...prevState,
        module: exam.modules[activeStep]?.id || 0
      }));
    }
  }, [activeStep, exam.modules]);
  const closeCreateQuestionModal = () => {
    setCreateQuestionOpen(false);
  };

  useEffect(() => {
    const fetchAndSetExam = async () => {
      try {
        await dispatch(fetchExams(examId));

      } catch (err) {
        setError('Failed to fetch exam details');
      } finally {
        setIsLoading(false);
      }
    };

    fetchAndSetExam();
  }, [dispatch, examId]);

  const handleBack = () => {
    navigate('/manage-exams');
  };

 


  const openDeleteConfirmDialog = (questionId) => {
    
    setDeletingQuestionId(questionId);
    setIsDeleteConfirmOpen(true);
  };
  
  
  const closeDeleteConfirmDialog = () => {
    setIsDeleteConfirmOpen(false);
  };
  const handleDeleteQuestion = (questionId) => {
    const deletingQuestion = exam.modules[activeStep].questions.find(q => q.id === questionId);
  
    if (!deletingQuestion) {
      console.error('Question not found');
      handleSnackbarOpen('Question not found', 'error'); // Display a message for not found
      return;
    }
  
    dispatch(deleteQuestion(questionId))
      .then(() => {
        // Update the order of remaining questions
        exam.modules[activeStep].questions
          .filter(q => q.order > deletingQuestion.order)
          .forEach(question => {
            dispatch(updateQuestionOrder(question.id, question.order - 1));
          });
  
        // Refetch exams to reflect the changes
        dispatch(fetchExams(examId));
        handleSnackbarOpen('Question deleted successfully', 'success');
      })
      .catch((err) => {
        if (err.response && err.response.status === 403) {
          // Handle 403 Forbidden error
          handleSnackbarOpen('Failed to delete the question, no permission', 'error');
        } else {
          // Handle other errors
          handleSnackbarOpen('Failed to delete the question', 'error');
        }
      });
  
    setIsDeleteConfirmOpen(false);
  };
  

  const handleEditNameClick = () => {
    setIsEditingName(true);
    setNewExamName(exam.name);
  };
  
  const openDeleteExamConfirmDialog = () => {
    setIsDeleteExamConfirmOpen(true);
  };
  
  const closeDeleteExamConfirmDialog = () => {
    setIsDeleteExamConfirmOpen(false);
  };
  
  const handleDeleteExam = async () => {
    try {
      const response = await deleteExamAPI(examId);
      if (response.status === 204) {
        // console.log("Exam deleted successfully");
        navigate('/manage-exams'); // Navigate back to the exams list
      }
    } catch (error) {
      setError('Failed to delete the exam');
    }
    closeDeleteExamConfirmDialog();
  };
  const handleTogglePublishStatus = () => {
    const newPublishStatus = !isPublished;
    setIsPublished(newPublishStatus);
  
    dispatch(setPublishStatus(examId, newPublishStatus))
      .then(() => {
        // console.log('Publish status updated successfully');
        // Optionally, you can refetch exam details or handle UI updates here
      })
      .catch((error) => {
        console.error('Error setting publish status:', error);
        setError('Failed to update publish status');
        // Revert the publish status toggle in case of error
        setIsPublished(isPublished);
      });
  };
  

  const handleCreateQuestion = () => {
    if (!exam.modules || exam.modules.length === 0) {
    console.error('No modules available');
    return;
    }

  const maxOrder = exam.modules[activeStep].questions.reduce((max, question) => Math.max(max, question.order), 0);
  const questionData = {
    ...newQuestion,
    text: richTextContentQuestionText,
    description: richTextContentDescription,
    module: exam.modules[activeStep]?.id,
    choices: richTextChoices,
    order: maxOrder + 1, // Assign the next order value
  };
  
  dispatch(createQuestion(examId, questionData))
  .then(() => {
    dispatch(fetchExams(examId));
    closeCreateQuestionModal();
    handleSnackbarOpen('Question added successfully', 'success');
  })
  .catch((err) => {
    setError('Failed to create a new question');
    handleSnackbarOpen('Failed to add question', 'error');
  });
};


  const resetQuestionForm = () => {
    setNewQuestion({
      text: '',
      description: '',
      question_type: 'MC',
      order: 0,
      correct_choice: 0,
      correct_numerical_answer: 0,
      module: 0,
    });
    setRichTextContentQuestionText('');
    setRichTextContentDescription('');
    setRichTextChoices(['', '', '', '']);
    closeCreateQuestionModal();
  };
  
  // console.log('richTextChoices',richTextChoices);
  
  const handleStep = (step) => () => {
    setActiveStep(step);
  };


  const handleChoiceChange = (index) => (content) => {

    const updatedChoices = [...richTextChoices];
    updatedChoices[index] = content;
    setRichTextChoices(updatedChoices);
    // console.log('being called again, ',updatedChoices);
  };

  const handleCorrectChoiceChange = (event) => {
    setNewQuestion({
      ...newQuestion,
      correct_choice: parseInt(event.target.value, 10),
    });
  };



  const handleRichTextChangeQuestionText = (content, delta, source, editor) => {
    setRichTextContentQuestionText(content);
  };

  const handleRichTextChangeDescription = (content, delta, source, editor) => {
    setRichTextContentDescription(content);
  };
  const handleSaveName = () => {
    if (newExamName.trim() === '') {
      handleSnackbarOpen('Exam name cannot be empty', 'error');
      return;
    }

    dispatch(updateExamName(examId, newExamName))
      .then(() => {
        dispatch(fetchExams(examId));
        handleSnackbarOpen('Exam name updated successfully', 'success');
        setIsEditingName(false);

      })
      .catch((err) => {
        // setError('Failed to update exam name');
        handleSnackbarOpen('Failed to update exam name, due to duplicate naming', 'error');
      });

  };

  const handleCancelEditName = () => {
    setIsEditingName(false);
    setNewExamName('');
  };


  if (isLoading) {
    return <LoadingOverlay/>;
    
  }

  // if (error) {
  //   return <Typography color="error">{error}</Typography>;
  // }

  return (
    <Container maxWidth="lg">

<Box sx={{ width: '100%', margin: '20px 0' }}>
                <Breadcrumbs aria-label="breadcrumb">
               
                    <MuiLink underline="hover" color="inherit" onClick={() => navigate('/manage-exams')}                sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}  // And optionally add this for consistency
>   Manage Exams
                    </MuiLink>
                    <Typography color="text.primary">{exam.name}</Typography>

                    <Typography color="text.primary">Exam Details</Typography>
                </Breadcrumbs>
            </Box>
      <CardContent>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <OldHeader title="Exam Details" />
        {user.group_names.includes('admin') && (<IconButton
          color="error"
          aria-label="delete exam"
          onClick={openDeleteExamConfirmDialog}
          style={{ padding: '10px' }} >
          <DeleteOutlinedIcon />
        </IconButton>)}
      </div>

      {isEditingName ? (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              value={newExamName}
              onChange={(e) => setNewExamName(e.target.value)}
              label="Exam Name"
              variant="outlined"
              fullWidth
            />
            <Button onClick={handleSaveName} variant="contained" color="primary" style={{ marginLeft: '10px' }}>
              Save
            </Button>
            <Button onClick={handleCancelEditName} variant="contained" color="secondary" style={{ marginLeft: '10px' }}>
              Cancel
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h2">{exam.name}</Typography>
            {user.group_names.includes('admin') && (
              <IconButton color="primary" onClick={handleEditNameClick}>
                <EditOutlinedIcon />
              </IconButton>
            )}
          </div>
        )}
        <Typography variant="subtitle1" color="textSecondary">
          Type: {exam.exam_type}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          Duration: {exam.duration}
        </Typography>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <Typography variant="subtitle1" color="textSecondary" style={{ marginRight: '10px' }}>
            Status:
          </Typography>

          <Switch checked={isPublished} onChange={handleTogglePublishStatus} color="primary" />
          <Typography variant="subtitle1" color="textSecondary">
            {isPublished ? 'Published' : 'Draft'}
          </Typography>
        </div>

<Stepper
  nonLinear
  activeStep={activeStep}
  sx={{
    marginTop: '20px',
    marginBottom: '20px',
  }}
>
  {exam.modules.map((module, index) => (
    <Step key={module.id}>
      <StepButton
        onClick={handleStep(index)}
        sx={{
          '&:focus': {
            outline: 'none',
          },
          padding: '18px',
         
        }}
      >
        Module {index + 1}
      </StepButton>
    </Step>
  ))}
</Stepper>


        {exam.modules.length > 0 && (
          <Paper style={{ marginBottom: '10px', padding: '15px' }} elevation={2}>
          <Typography variant="h6" gutterBottom>
              Module {activeStep + 1}: {exam.modules[activeStep].name}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Time Limit: {exam.modules[activeStep].time_limit}
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Break Time After: {exam.modules[activeStep].break_time_after || 'None'}
            </Typography>

            <List dense>
            {exam.modules[activeStep].questions.map((question, qIndex) => (
  <Paper key={question.id} style={{ marginBottom: '10px', padding: '15px' }} elevation={2} className="question-hover-action">
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
      <div>
        <Typography variant="subtitle1" gutterBottom>
          <strong>Question {qIndex + 1}:</strong>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1" style={{ fontSize: '1.1rem' }}>
              <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.text) }} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle1"  style={{ fontSize: '1.1rem' }}>
              <div className="rich-text-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(question.description) }} />
            </Typography>
          </Grid>
        </Grid>
        {question.question_type === 'MC' && (
          <div>

            <Grid container spacing={2}>
              {renderChoicesInGrid(question.choices, question.correct_choice)}
            </Grid>
          </div>
        )}
        {question.question_type === 'NI' && (
          <Typography variant="body1" gutterBottom style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
            Answer: {question.correct_numerical_answer}
          </Typography>
        )}
      </div>
      <div className="action-buttons-container">
      <IconButton
  aria-label="edit question"
  onClick={() => openEditQuestionDialog(question)}
  color="primary"
  className="action-button"
>
  <EditOutlinedIcon />
</IconButton>
  <IconButton 
    aria-label="delete question"
    onClick={() => openDeleteConfirmDialog(question.id)} 
    color="error"
    className="action-button"

  >
    <DeleteOutlinedIcon />
  </IconButton>
</div>

    </div>
  </Paper>
))} </List>
 </Paper>
        )}
       <IconButton
        color="primary"
        aria-label="add a new question"
        component="span"
        style={{
          position: 'fixed',
          bottom: 20,
          right: 20,
          zIndex: 1000, // Ensure it's above other elements
        }}
        onClick={openCreateQuestionModal}
      >
        <AddCircleOutlineIcon style={{ fontSize: '2rem' }} />
      </IconButton>

        <Grid container spacing={2} marginTop={2}>
          <Grid item>
            <Button variant="contained"onClick={handleBack}>
              Back to Exams
            </Button>
          </Grid>

        </Grid>
      </CardContent>

      <CreateQuestionDialog
  isCreateQuestionOpen={isCreateQuestionOpen}
  closeCreateQuestionModal={closeCreateQuestionModal}
  newQuestion={newQuestion}
  setNewQuestion={setNewQuestion}
  richTextContentQuestionText={richTextContentQuestionText}
  handleRichTextChangeQuestionText={handleRichTextChangeQuestionText}
  richTextContentDescription={richTextContentDescription}
  handleRichTextChangeDescription={handleRichTextChangeDescription}
  richTextChoices={richTextChoices}
  handleChoiceChange={handleChoiceChange}
  handleCorrectChoiceChange={handleCorrectChoiceChange}
  handleCreateQuestion={handleCreateQuestion}
  user={user}
/>
{isEditQuestionOpen && editingQuestion && (
  <EditQuestionDialog
    isOpen={isEditQuestionOpen}
    onClose={() => setIsEditQuestionOpen(false)}
    question={editingQuestion}
    onSave={handleSaveEditQuestion}
    user = {user}
  />
)}


<Dialog
  open={isDeleteConfirmOpen}
  onClose={closeDeleteConfirmDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Confirm Question Deletion"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Are you sure you want to delete the question?
      <br />

       This action cannot be undone.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={closeDeleteConfirmDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={() => handleDeleteQuestion(deletingQuestionId)} color="error" autoFocus>
      Confirm
    </Button>
  </DialogActions>
</Dialog>


<Dialog
  open={isDeleteExamConfirmOpen}
  onClose={closeDeleteExamConfirmDialog}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title">{"Confirm Exam Deletion"}</DialogTitle>
  <DialogContent>
    <DialogContentText id="alert-dialog-description">
      Are you sure you want to delete this exam? This action cannot be undone.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={closeDeleteExamConfirmDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={handleDeleteExam} color="error" autoFocus>
      Confirm
    </Button>
  </DialogActions>
</Dialog>
<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
  <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
    {snackbarMessage}
  </Alert>
</Snackbar>
<Snackbar
  open={snackbarOpen}
  autoHideDuration={6000}
  onClose={handleSnackbarClose}
>
  <Alert
    onClose={handleSnackbarClose}
    severity={snackbarSeverity}
    sx={{ width: '100%' }}
  >
    {snackbarMessage}
  </Alert>
</Snackbar>

</Container>
  );
};

export default ViewExamDetails;
