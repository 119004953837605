import React, { useState, useCallback,useEffect,useRef } from 'react';
import { AppBar, Toolbar, Typography, Button, Grid, Box,useTheme, Paper, CircularProgress,styled,Popover, Drawer, List, ListItem, ListItemText, Divider, IconButton } from '@mui/material';


import DesmosCalculator from './Desmos';
import { Rnd } from 'react-rnd';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import referenceTable from '../scenes/css/images/mathreference.png';

const CalculatorAppBar = ({ onExpand, onToggle, isExpanded,toggleCalculator,isCalculator }) => {
    return (
      <AppBar position="static" className="drag-handle" sx={{ height: '5.5vh',
      '&:focus': {
        outline: 'none',
      } }}
>
      <Toolbar >
          <Typography variant="h5" sx={{ marginRight: 'auto',fontWeight: 'bold' }}>
          {isCalculator ? 'Calculator' : 'Reference Table'}
          </Typography>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <DragIndicatorOutlinedIcon sx={{ transform: 'rotate(90deg)' }} />
          </Box>
          <IconButton color="inherit" onClick={onExpand} sx={{ '&:focus': {
        outline: 'none',
      } }}>
{isCalculator && (
            <IconButton color="inherit" onClick={onExpand} sx={{ '&:focus': { outline: 'none' } }}>
              {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          )}          </IconButton>
          <IconButton color="inherit" onClick={toggleCalculator} sx={{ '&:focus': {
        outline: 'none',
      } }}>
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  };
  
  
  
  const CalculatorComponent = ({isVisible,toggleVisibility,
    position,
    onPositionChange,
    isExpanded,
    setIsExpanded,myCalculatorRef,toggleCalculator,isCalculator}) => {


    const containerStyle = {
      zIndex: 9999, // High z-index value
      display: isVisible ? 'block' : 'none', // Control visibility with display property


    };
    useEffect(() => {
      if (isCalculator && myCalculatorRef.current) {
        myCalculatorRef.current.style.display = isVisible ? 'block' : 'none';
      }
    }, [isVisible, myCalculatorRef]);

    const size = isCalculator ? 
    isExpanded ? { width: 840, height: 620 } : { width: 430, height: 540 } : 
    { width: 840, height: 620 }; // Max size for reference sheet

    return (
        <Rnd
        size={size}
        position={position}
        onDragStop={(e, d) => onPositionChange({ x: d.x, y: d.y })}
        onResizeStop={(e, direction, ref, delta, position) => {
          setIsExpanded(true);
          onPositionChange(position);
        }}
        minWidth="200"
        minHeight="200"
        bounds="parent"
        dragHandleClassName="drag-handle"
        enableResizing={false}
        style={containerStyle}
      >
        <CalculatorAppBar
          onExpand={() => setIsExpanded(!isExpanded)}
          onToggle={toggleVisibility}
          isExpanded={isExpanded}
          toggleCalculator={toggleCalculator}
          isCalculator={isCalculator}
        />
            {isCalculator ? <DesmosCalculator ref={myCalculatorRef} /> : <img src={referenceTable} alt="Reference Sheet" style={{ width: '100%', height: '100%' }} />}
      </Rnd>
    );
  };
  
  export default CalculatorComponent;
