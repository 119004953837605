import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns'; // Importing the date-fns adapter

// Register chart components and plugins
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale, zoomPlugin);

const ExamChart = ({ studentExams }) => {
  const chartData = () => {
    return studentExams.reduce((acc, exam) => {
      acc.labels.push(exam.exam_schedule.start_time);
      acc.datasets[0].data.push({ x: exam.exam_schedule.start_time, y: exam.eng_score + exam.math_score, id: exam.id });
      acc.datasets[1].data.push({ x: exam.exam_schedule.start_time, y: exam.math_score, id: exam.id });
      acc.datasets[2].data.push({ x: exam.exam_schedule.start_time, y: exam.eng_score, id: exam.id });
      return acc;
    }, {
      labels: [],
      datasets: [
        { label: 'Full SAT Score', data: [], borderColor: 'rgba(75,192,192,1)', backgroundColor: 'rgba(75,192,192,0.2)' },
        { label: 'Math SAT Score', data: [], borderColor: 'rgba(255,206,86,1)', backgroundColor: 'rgba(255,206,86,0.2)' },
        { label: 'English SAT Score', data: [], borderColor: 'rgba(255,99,132,1)', backgroundColor: 'rgba(255,99,132,0.2)' }
      ]
    });
  };

  const options = {
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'PPP',
        }
      },
      y: {
        beginAtZero: false,
        suggestedMin: studentExams.length ? Math.min(...studentExams.map(exam => exam.eng_score + exam.math_score)) : undefined,
        suggestedMax: 1599, // Highest possible SAT score
        ticks: {
          stepSize: 100,
          precision: 0
        }
      }
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: true,
        mode: 'index',
        intersect: false,
      },
      zoom: {
        limits: {
          x: { min: 'original', max: 'original' },
        },
        zoom: {
          wheel: { enabled: true },
          pinch: { enabled: true },
          mode: 'x'
        },
        pan: {
          enabled: true,
          mode: 'x'
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  return (
    <Line data={chartData()} options={options} />
  );
};

export default ExamChart;
