import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography, Container, Link } from "@mui/material";
import Header from '../../components/Header';
import BugReportIcon from '@mui/icons-material/BugReport'; // If you're using MUI icons
import releaseNotesData from '../releaseNotes/notes.json';
import hello from './hello.json';
import { fetchOneStudent } from "../../actions/auth";
import Student from './student';
import Admin from './admin';
const Dashboard = () => {
  const user = useSelector(state => state.auth.user);
  return (
    <>{(user?.group_names[0] === 'student')? (<Student/>):(<Admin/>)}</>
  );
};

export default Dashboard;