import React from 'react';

import LoginFormComponent from "../components/LoginFormComponent.tsx"
import "../components/containerH.css";
 
function Login() {
    return (
        <div className="containerH">
        <LoginFormComponent/>
        </div>

    )
}
export default Login;
