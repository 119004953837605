import React, { useEffect, useState } from 'react';
import { Box, Button, IconButton, Container,Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useSelector } from 'react-redux';

import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Header from '../../components/Header';
import StatBox from "../../components/StatBox";
import { countusers,fetchRecentFinishedExam,countstudentexams } from '../../actions/auth';
import { useNavigate } from 'react-router-dom';
import hello from './hello.json';

const Admin = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [totalUsers, setTotalUsers] = useState({});
  const [finishedExams, setfinishedExam] = useState([]);
  const [countTotalExam, setcountTotalExam] = useState([]);
// console.log('finishedExams',finishedExams)
const [greeting, setGreeting] = useState(''); // State to store the greeting
const user = useSelector(state => state.auth.user);

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const handleExamClick = (id) => {
    navigate(`/exam-details/${id}`);
  };
  useEffect(() => {
    const fetchTotalUsers = async () => {
      try {
        const totalUsersCount = await countusers();
        setTotalUsers(totalUsersCount);
        // setLoading(false);
      } catch (error) {
        console.error('Error fetching total users:', error);
      }
    };
    
    const fetchFishiedExam = async () => {
      try {
        const finishedExam = await fetchRecentFinishedExam();
        setfinishedExam(finishedExam)
        // console.log('fishied',finishedExam)
      } catch (error) {
        console.error('Error fetching finished exam:', error);
      }
    };
    //countstudentexams

    const countstudentExam = async () => {
      try {
        const countExam = await countstudentexams();
        // setfinishedExam(finishedExam)
        setcountTotalExam(countExam);
        // console.log('fishied',countExam)
        setLoading(false);
      } catch (error) {
        console.error('Error fetching finished exam:', error);
      }
    };
//fetchRecentFinishedExam
    fetchTotalUsers();
    fetchFishiedExam();
    countstudentExam();
  }, []);

  useEffect(() => {
    const getRandomGreeting = () => {
      const randomIndex = Math.floor(Math.random() * hello.length);
      const greeting = hello[randomIndex].hello;
      return `${greeting}, ${user?.first_name || 'Guest'}!`; // Localized greeting with the user's first name
    };
    setGreeting(getRandomGreeting());
  }, [user]); // Add user to the dependency array to re-run when user changes


  return (
    <Container sx={{ margin: '20px' }}>
      {/* HEADER */}
      <Box sx={{ width: '100%', mt: 4 }}>
        {/* <Header title="DASHBOARD" subtitle="Welcome to your dashboard" /> */}
        <Header
  title={greeting}
  subtitle={""}
  page="dashboard"
/>
        {/* <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box> */}
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
  
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <StatBox
              title={totalUsers.new_users_last_30_days?.toString() || '0'}
              subtitle="New Users (30 Days)"
              progress={(totalUsers.new_users_percentage*0.01 || 0).toFixed(2)}
              increase={`+${(totalUsers.new_users_percentage || 0).toFixed(2)}%`}
              icon={
                <PersonAddIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          )}
        </Box>
        <Box
  gridColumn="span 3"
  backgroundColor={colors.primary[400]}
  display="flex"
  alignItems="center"
  justifyContent="center"
>
{loading ? (
            <Typography>Loading...</Typography>
          ) : (
  <StatBox
    title={countTotalExam.total_completed_exams}
    subtitle="Completed Exams (30 Days)"
    progress={(countTotalExam.completion_percentage * 0.01 || 0).toFixed(2)}
    increase={`+${(countTotalExam.completion_percentage || 0).toFixed(2)}%`}
    icon={
      <CheckCircleOutlineIcon
        sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
      />
    }
  />)}
</Box>
       
        <Box
          gridColumn="span 12"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Recent Completed Exams
            </Typography>
          </Box>
          {finishedExams.map((transaction, i) => (
          <Box
            key={`${transaction.id}-${i}`}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            p="15px"
            onClick={() => handleExamClick(transaction.id)}
            sx={{ cursor: 'pointer' }}

          >

            <Box>
              <Typography
                color={colors.greenAccent[500]}
                variant="h5"
                fontWeight="600"
              >
                {transaction.student_info.name} - {transaction.exam_name} -{transaction.schedule_type}
              </Typography>
              
              <Typography color={colors.grey[100]}>
              Total Score: {transaction.schedule_type !== 'Full' ? '-' : transaction.math_score+transaction.eng_score}
              </Typography>
              <Typography color={colors.grey[100]}>
              Math Score: {transaction.schedule_type === 'English' ? '-' : transaction.math_score}
              </Typography>
              <Typography color={colors.grey[100]}>
              English Score: {transaction.schedule_type === 'Math' ? '-' : transaction.eng_score}
              </Typography>
            </Box>
            <Box color={colors.grey[100]}>
            {new Date(transaction.completed_exam_time).toLocaleString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}
            </Box>
          </Box>
        ))}
        </Box>

      
      </Box>
    </Container>
  );
};

export default Admin;
