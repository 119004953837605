import React from 'react';
import SectionDisplay from './SectionDisplay';
import Countdown1 from '../../components/Countdown';
// import { Button } from '@mui/material';
import calculatorIcon from '../css/images/calculator.png'; 
import referenceIcon from '../css/images/reference.png';

const TopHeader =  React.memo(({ currentExam, endTime, handleTimeout,isMathModule,toggleCalculator,toggleReference }) => {
  // console.log('re rederned top header')
  // console.log(endTime)
  return (
    <header className="top-header">
      <nav className="navbar navbar-expand">
        <div className="left-topbar d-flex col-md-4">
          <SectionDisplay order={currentExam?.current_module?.name}/>
          <div className="direction" data-toggle="modal" data-target="#directionmodal">
            <span className="header-desc">Directions</span>
            <div id="arrow" className="arrow"></div>
          </div>
        </div>

        <Countdown1 endTime={endTime} onTimeout={handleTimeout} />

        {typeof isMathModule === 'function' && isMathModule() && (<div className="right-topbar col-md-4">
          <div className="tools">
          <div className="single-tool" id="show-calculator" onClick={toggleCalculator}>
        <div className="tool-icon">
        <img src={calculatorIcon} alt="calculator" />
        </div>
        <span className="header-desc">Calculator</span>
      </div>
      <div className="single-tool" id="show-reference" onClick={toggleReference}>
        <div className="tool-icon">
          <img src={referenceIcon} alt="reference" />
        </div>
        <span className="header-desc">Reference</span>
      </div>
          
          </div>
        </div>)}
      </nav>
    </header>
  );
});

export default TopHeader;