import React from 'react';
import { Container, Typography, Box, Link, Button } from '@mui/material';
import Confetti from 'react-confetti';
import './CongratulationsPage.css';
import { useParams, useNavigate } from 'react-router-dom';
import BugReportIcon from '@mui/icons-material/BugReport'; // If you're using MUI icons

const CongratulationsPage = () => {
    const { studentexamId } = useParams();
    const navigate = useNavigate();

    const handleContinueClick = () => navigate(`/exam-details/${studentexamId}`);
    const handleHomeClick = () => navigate('/my-exams');

    return (
        <Box className="congratulations-container">
            <Confetti />
            <h1 class="text-5xl text-green-500 font-bold mb-8 animate-pulse hover:scale-110 transition-transform duration-500">
    Congratulations!
</h1>

    <p class="text-black text-lg mb-8">
    You've successfully completed the EXAM!    </p>
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <Link href="https://docs.google.com/forms/d/e/1FAIpQLSfLssMK1KzgCfrLO9f-lccg_PZ08L2qnotZuWuYIRZrXCgoow/viewform?usp=sf_link" target="_blank" color="inherit" sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                    <BugReportIcon sx={{ mr: 1 }} />
                    Report a Bug
                </Link>
            </Box>

            <Box className="congratulations-buttons">
                <Button variant="contained" color="primary" onClick={handleHomeClick}>
                    Back to Home Page
                </Button>
                <Button variant="contained" color="secondary" onClick={handleContinueClick}>
                    View Your Score
                </Button>
            </Box>
            {/* <footer>
                &copy; {new Date().getFullYear()} Your Company Name. All Rights Reserved.
            </footer> */}
        </Box>
    );
};

export default CongratulationsPage;
