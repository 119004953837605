import axios from 'axios';
import { resolveObjectURL } from 'buffer';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  LOGOUT,
  FETCH_SCHEDULED_EXAMS_SUCCESS,
  FETCH_EXAMS_SUCCESS,
  FETCH_EXAM_DETAILS_SUCCESS,
  CREATE_EXAM_SUCCESS,
  ACCESS_TOKEN_REFRESHED
} from './types';

//total-users
//countstudentexams
//total-studentexams
export const countstudentexams = async () => {
  try {
    const token = localStorage.getItem('access');
    if (token) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
        }
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/exam/studentexams/total-studentexams/`, config);
      return response.data;
    } else {
      throw new Error('No access token found');
    }
  } catch (error) {
    console.error('Error fetching total users:', error);
    throw error;
  }
};

export const countusers = async () => {
  try {
    const token = localStorage.getItem('access');
    if (token) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${token}`,
        }
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/user/total-users/`, config);
      return response.data;
    } else {
      throw new Error('No access token found');
    }
  } catch (error) {
    console.error('Error fetching total users:', error);
    throw error;
  }
};


export const load_user = () => async dispatch => {
  try {
    if (localStorage.getItem('access')) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
        }
      };
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/user/get-info/`, config);
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: response.data
      });

    } else {
      dispatch({
        type: USER_LOADED_FAIL
      });
    }

  } catch (error) {
    console.error('load_user error:', error);
        throw error;

  }
};

export async function fetchUsers() {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/user/user/`, {
      headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${localStorage.getItem('access')}`,
      },
  });
  const data = await response.json();
  return data;
}

export const createExam = (name, description, exam_type, duration) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/exam/exams/`,
      { name, description, exam_type, duration },
      config
    );
    if (response.status === 201) {
      dispatch({ type: CREATE_EXAM_SUCCESS, payload: response.data });
      return response;

    } else {
      console.error('Unexpected response status:', response.status, response.data);
      // Dispatch failure action here if necessary
    }
  } catch (error) {
    console.error('Error creating exam:', error);
    throw error;

  }
};

//updateExamName

export const updateExamName = (examId,name) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/exam/exams/${examId}/`,
      { name },
      config
    );
    if (response.status === 200) {
      // dispatch({ type: FETCH_EXAMS_SUCCESS, payload: response.data });
      return response;

    } else {
      console.error('Unexpected response status:', response.status, response.data);
      // Dispatch failure action here if necessary
    }
  } catch (error) {
    console.error('Error creating exam:', error);
    throw error;

  }
};



export const deleteExamAPI = async (examId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/exam/exams/${examId}/`,
      config
    );
    if (response.status === 204) {
      return response;

    } else {
      console.error('Unexpected response status:', response.status, response.data);
      // Dispatch failure action here if necessary
    }
  } catch (error) {
    console.error('Error deleting exam:', error);
    throw error;

  }
};

export const deleteModuleAPI = async (examId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/exam/modules/${examId}/`,
      config
    );
    if (response.status === 204) {
      return response;

    } else {
      console.error('Unexpected response status:', response.status, response.data);
      // Dispatch failure action here if necessary
    }
  } catch (error) {
    console.error('Error deleting exam:', error);
    throw error;

  }
};


export const updateQuestionOrder = (questionId, newOrder) => async dispatch => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCsrfToken(),
        ...(accessToken ? { 'Authorization': `JWT ${accessToken}` } : {})
      }
    };

    const payload = {
      order: newOrder
    };

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/exam/questions/${questionId}/`, 
      payload, // Send the new order in the payload
      config
    );

    if (response.status === 200) {
      // Dispatch success action here, if needed
      // For example, you might want to update the local state to reflect the new order
      // dispatch({ type: 'UPDATE_QUESTION_ORDER_SUCCESS', payload: { questionId, newOrder } });
    }
  } catch (error) {
    console.error('Error updating question order:', error);
    throw error;

  }
};

export const createQuestion = (examId, questionData) => async dispatch => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCsrfToken(),
        ...(accessToken ? { 'Authorization': `JWT ${accessToken}` } : {})
      }
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/exam/questions/`, 
      questionData, // No need to stringify if axios is used
      config
    );

    if (response.status === 201) {
      // Dispatch success action here, if needed
      // dispatch({ type: 'CREATE_QUESTION_SUCCESS', payload: response.data });
    }
  } catch (error) {
    console.error('Error creating question:', error);
    throw error;

  }
};

export const editImgPercentage =(userid,imageScale) => async(dispatch)=> {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  };

  // Assuming 'password' needs to be part of the request, you need to include it in the parameters

  try {
    const res = await axios.patch(`${process.env.REACT_APP_API_URL}/auth/users/${userid}/`, imageScale, config);

    return res;
  } catch (err) {
    console.error('update image scal percentage Error: ', err.response.data);

    throw err;
  }
};

export const editQuestion = (questionData,questionId) => async dispatch => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCsrfToken(),
        ...(accessToken ? { 'Authorization': `JWT ${accessToken}` } : {})
      }
    };
    // console.log('questiondada',questionData);
    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/exam/questions/${questionId}/`, 
      questionData, 
      config
    );

  } catch (error) {
    console.error('Failed to edit question:', error);
    throw error;
  }
};

export const deleteQuestion = (questionId) => async dispatch => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/exam/questions/${questionId}/`,
      config
    );

    if (response.status === 204) {
      // Optionally, you can handle post-deletion logic here (like refreshing a list)
    } else {
      console.error('Unexpected response status:', response.status, response.data);
    }
  } catch (error) {
    console.error('Error deleting question:', error);
    throw error;
  }
};

export const deleteSchedule = (scheduleId) => async dispatch => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    await axios.delete(
      `${process.env.REACT_APP_API_URL}/exam/examschedules/${scheduleId}/`,
      config
    );

  } catch (error) {
    console.error('Error deleting schedule:', error);
    throw error;
  }
};

export const setPublishStatus = (examId, shouldPublish) => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.patch(
      `${process.env.REACT_APP_API_URL}/exam/exams/${examId}/`,
      { is_published: shouldPublish },
      config
    );

    if (response.status === 200 || response.status === 201) {
    } else {
      console.error('Unexpected response status:', response.status, response.data);
    }
  } catch (error) {
    console.error('Error setting publish status:', error);
  }
};



export const startExams = async (examId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/exam/studentexams/${examId}/start-exam/`,{},
      config
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Unexpected response status:', response.status, response.data);
      return null;
    }
  } catch (error) {
    console.error('Error starting exam:', error);
    return null;
  }
};

export const scheduleExam = async (examId, startTime, endTime, studentsId,scheduleType) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }
    const uniqueStudentsId = Array.from(new Set(studentsId));

    // Prepare data
    const postData = {
      exam: examId,
      start_time: startTime,
      end_time: endTime,
      students_for_write: uniqueStudentsId,
      schedule_type: scheduleType
  
    };

    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/exam/examschedules/`,
      JSON.stringify(postData),
      config
    );

    if (response.status === 201) {
      return response.data;
    } else {
      console.error('Unexpected response status:', response.status, response.data);
      return null;
    }
  } catch (error) {
    console.error('Error scheduling exam:', error);
    return null;
  }
};

export const listscheduleExam = (currentPage=1) => async (dispatch) => {
  try {
    // console.log('list')
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/exam/examschedules/?page=${currentPage}`,
      config
    );

    if (response.status === 200) {
      dispatch({ type: FETCH_SCHEDULED_EXAMS_SUCCESS, payload: response.data });
    } else {
      console.error('Unexpected response status:', response.status, response.data);
      return null;
    }
  } catch (error) {
    console.error('Error starting exam:', error);
    return null;
  }
};
export const liststudentExam = async (scheduleid) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/exam/examschedules/${scheduleid}/schedule-details/`,
      config
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.error('Unexpected response status:', response.status, response.data);
      return null;
    }
  } catch (error) {
    console.error('Error get studentexams:', error);
    return null;
  }
};




export const deleteScheduleExam = async (examid) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.delete(
      `${process.env.REACT_APP_API_URL}/exam/examschedules/${examid}/`,
      config
    );

    if (response.status === 204) {
      return response.status;
    } else {
      console.error('Unexpected response status:', response.status, response.data);
      return null;
    }
  } catch (error) {
    console.error('Error deleting exam:', error);
    return null;
  }
};

export const getStudentExamBySchedule = async (scheduleId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/exam/studentexams/retrieve-by-schedule/${scheduleId}/`,
      config
    );
    if (response.status === 200) {
      // console.log('response id', response.data.id);
      // console.log(response);
      return response.data.id;
    } else {
      console.error('Unexpected response status:', response.status, response.data);
      return null;
    }
  } catch (error) {
    console.error('Error starting exam:', error);
    return null;
  }
};

export const getStudentExamByScheduleT = async (scheduleId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/exam/studentexams/retrieve-by-schedule/${scheduleId}/`,
      config
    );
    if (response.status === 200) {
      // console.log('response id', response.data.id);
      // console.log(response);
      if (Array.isArray(response.data)) {
        // Handle the case where the data is an array (likely for teachers and admins)
        // Assuming you want to take the first item's id
        return response.data.length > 0 ? response.data[0].id : null;
      } else {
        // Handle the case where the data is a single object (likely for students)
        return response.data.id;
      }
    } else {
      console.error('Unexpected response status:', response.status, response.data);
      return null;
    }
  } catch (error) {
    console.error('Error starting exam:', error);
    return null;
  }
};
//schedule exams
export const fetchScheduledExams = () => async (dispatch) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    // Add JWT token to headers if it exists
    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/exam/studentexams/all-exams/`,
      config
    );
    // Check if the response status is OK (status code 200)
    if (response.status === 200) {
      // Assuming the data is directly the array of exams
      dispatch({ type: FETCH_SCHEDULED_EXAMS_SUCCESS, payload: response.data });
    } else {
      // Handle other HTTP status codes if needed
      console.error('Unexpected response status:', response.status, response.data);
    }
  } catch (error) {
    console.error('fetchScheduledExams error:', error);
    // Handle error as needed
  }
};

export const fetchOneStudent = async (studentId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };

    // Add JWT token to headers if it exists
    if (accessToken) {
      config.headers['Authorization'] = `JWT ${accessToken}`;
    }
    // console.log('student id',studentId)

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/exam/studentexams/exams-for-student/?student_id=${studentId}`,
      config
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    console.error('fetch one student error:', error);
    // Handle error as needed
  }
};



export const autosaveAnswer = (questionId, answer, studentexamId) => async dispatch => {
  try {

    const accessToken = localStorage.getItem('access');

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${accessToken}`,
        'X-CSRFToken': getCsrfToken(),

      },
    };

    const body = JSON.stringify({ answer });
    // console.log('body',answer);
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/exam/autosave-answer/${studentexamId}/${questionId}/`, body, config);

    if (response.status === 200) {
        // console.log('saved success')
      // toast.success('Answer autosaved successfully');
    } else {
      throw new Error('Failed to autosave answer');
    }
  } catch (error) {
    console.error('Autosave error:', error);
    // toast.error('Error autosaving answer, please try again.');
    // Optional: Implement retry logic here
  }
};

export const fetchSavedAnswers = async (studentexamId) => {
  try {

    const accessToken = localStorage.getItem('access');

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${accessToken}`,
        'X-CSRFToken': getCsrfToken(),

      },
    };

    const response = await axios.get(`${process.env.REACT_APP_API_URL}/exam/student-exam/${studentexamId}/saved-answers/`, config);

    if (response.status === 200) {
      return response.data; // Returns the saved answers

      // toast.success('Answer autosaved successfully');
    } else {
      throw new Error('Failed to get autosave answer');
    }
  } catch (error) {
    console.error('Error fetching saved answers:', error);
    // toast.error('Error autosaving answer, please try again.');
    // Optional: Implement retry logic here
  }
};





export const fetchExams = (examId = null) => async dispatch => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRFToken': getCsrfToken(),
        ...(accessToken ? { 'Authorization': `JWT ${accessToken}` } : {})
      }
    };

    let url = `${process.env.REACT_APP_API_URL}/exam/exams/${examId ? `${examId}/` : ''}`;
    const response = await axios.get(url, config);
    if (response.status === 200) {
      const actionType = examId ? FETCH_EXAM_DETAILS_SUCCESS : FETCH_EXAMS_SUCCESS;
      dispatch({ type: actionType, payload: response.data });
      return response.data;
    } else {
      throw new Error(`Unexpected response status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error fetching exams:', error);
    throw error; // Re-throwing for UI handling
  }
};


export const moveNextModule = async (studentexamId) => {
  try {
    // console.log('called move NExt module')
    const accessToken = localStorage.getItem('access');

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `JWT ${accessToken}`,
        'X-CSRFToken': getCsrfToken(),
        
      },
    };

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/exam/studentexams/${studentexamId}/next-or-complete/`, null,config);

    if (response.status === 200) {
      return response.data; 
    } else {
      throw new Error('Failed to get autosave answer');
    }
  } catch (error) {
    console.error('Error fetching saved answers:', error);
  }
};

export const fetchExamForPreview = async (examId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
        'Authorization': accessToken ? `JWT ${accessToken}` : '',
      },
    };
    const answersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/exam/exams/${examId}/`, config);
    const savedAnswers = answersResponse.data;
    return savedAnswers;
  } catch (error) {
    
    console.error('Error fetching exam:', error);
    throw error;
  }
};


export const fetchExamDetails = async (studentexamId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
        'Authorization': accessToken ? `JWT ${accessToken}` : '',
      },
    };
    const examResponse = await axios.get(`${process.env.REACT_APP_API_URL}/exam/studentexams/${studentexamId}/`, config);
    const examData = examResponse.data;
    const answersResponse = await axios.get(`${process.env.REACT_APP_API_URL}/exam/student-exam/${studentexamId}/saved-answers/`, config);
    const savedAnswers = answersResponse.data;

    return { exam: examData, savedAnswers: savedAnswers };
  } catch (error) {
    
    console.error('Error fetching exam:', error);
    throw error;
  }
};


export const fetchExamTime = async (studentexamId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
        'Authorization': accessToken ? `JWT ${accessToken}` : '',
      },
    };
    const timerResponse = await axios.get(`${process.env.REACT_APP_API_URL}/exam/studentexams/${studentexamId}/sync-timer/`, config);
    const examTimer = timerResponse.data;
    
    return examTimer ;
  } catch (error) {
    console.error('Error fetching timer:', error);
    throw error;
  }
};
export const regradeExam = async (examId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
        'Authorization': accessToken ? `JWT ${accessToken}` : '',
      },
    };
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/exam/regrade-exam/${examId}/`, config);
    // console.log('response',response)
    return response;
  } catch (error) {
    console.error('Error fetching timer:', error);
    throw error;
  }
};


export const skipBreak = async (studentexamId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
        'Authorization': accessToken ? `JWT ${accessToken}` : '',
      },
    };
    const timerResponse = await axios.post(`${process.env.REACT_APP_API_URL}/exam/studentexams/${studentexamId}/skip-break/`,null,config);
    
    return timerResponse.status ;
  } catch (error) {
    console.error('Error fetching timer:', error);
    throw error;
  }
};

//recent-completed-exams/

export const fetchRecentFinishedExam = async (studentexamId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
        'Authorization': accessToken ? `JWT ${accessToken}` : '',
      },
    };
    const examResponse = await axios.get(`${process.env.REACT_APP_API_URL}/exam/recent-completed-exams/`, config);
    const examData = examResponse.data;

    return examData;
  } catch (error) {
    console.error('Error fetching exam:', error);
    throw error;
  }
};
export const fetchExamAnswers = async (studentexamId) => {
  try {
    const accessToken = localStorage.getItem('access');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRFToken': getCsrfToken(),
        'Authorization': accessToken ? `JWT ${accessToken}` : '',
      },
    };
    const examResponse = await axios.get(`${process.env.REACT_APP_API_URL}/exam/studentexams/${studentexamId}/exam-details/`, config);
    const examData = examResponse.data;

    return examData;
  } catch (error) {
    console.error('Error fetching exam:', error);
    throw error;
  }
};

// Check authentication function
export const checkAuthenticated = () => async dispatch => {
  const accessToken = localStorage.getItem('access');
  const refreshToken = localStorage.getItem('refresh');

  if (!accessToken || !refreshToken) {
    dispatch({ type: AUTHENTICATED_FAIL });
    return;
  }

  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRFToken': getCsrfToken()
    }
  };

  const body = JSON.stringify({ token: accessToken });

  try {
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config);
    dispatch({ type: AUTHENTICATED_SUCCESS });
  } catch (error) {
    if (error.response && error.response.status === 401) {
      // Token is not valid, try to refresh it
      await tryRefreshingToken(refreshToken, config, dispatch);
    } else {
      // Other kinds of errors
      console.error('Error during authentication check:', error);
      dispatch({ type: AUTHENTICATED_FAIL });
    }
  }
};

// tryRefreshingToken function
// Action creator for refreshing access token
export const refreshAccessToken = (newAccessToken) => ({
  type: ACCESS_TOKEN_REFRESHED,
  payload: newAccessToken
});

async function tryRefreshingToken(refreshToken, config, dispatch) {
  const refreshBody = JSON.stringify({ refresh: refreshToken });

  try {
    const refreshRes = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`, refreshBody, config);

    if (refreshRes.data.access) {
      dispatch(refreshAccessToken(refreshRes.data.access));
    } else {
      dispatch({ type: AUTHENTICATED_FAIL });
    }
  } catch (refreshError) {
    console.error('Error refreshing token:', refreshError);
    dispatch({ type: AUTHENTICATED_FAIL });
  }
}



// Function to get CSRF token
const getCsrfToken = () => {
  return localStorage.getItem('csrfToken');
};

// Setting up Axios interceptor
axios.interceptors.request.use(request => {
  request.headers['X-CSRFToken'] = getCsrfToken();
  return request;
});

const setupInterceptors = (dispatch) => {
  axios.interceptors.response.use(
    response => response,
    async (error) => {
      const originalRequest = error.config;

      // If there's no response or it's not a 401 error, return the error
      if (!error.response || error.response.status !== 401) {
        return Promise.reject(error);
      }

      // If this request is for refreshing the token, handle the specific error
      if (originalRequest.url === `${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`) {
        if (error.response.data.code === "token_not_valid") {
          handleFailedTokenRefresh(dispatch);
          return Promise.reject(error);
        }
      }

      // If this request has already been retried, don't retry again
      if (originalRequest._retry) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refresh');

      try {
        const refreshRes = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`, JSON.stringify({ refresh: refreshToken }), {
          headers: {
            'Content-Type': 'application/json',
            'X-CSRFToken': getCsrfToken()
          }
        });

        if (refreshRes.data.access) {
          localStorage.setItem('access', refreshRes.data.access);
          originalRequest.headers['Authorization'] = `JWT ${refreshRes.data.access}`;
          return axios(originalRequest);
        }

        handleFailedTokenRefresh(dispatch);
        return Promise.reject(error);

      } catch (refreshError) {
        console.error('Error refreshing token:', refreshError);
        handleFailedTokenRefresh(dispatch);
        return Promise.reject(refreshError);
      }
    }
  );
};

export default setupInterceptors;

const handleFailedTokenRefresh = (dispatch) => {
  dispatch({ type: AUTHENTICATED_FAIL });
  localStorage.removeItem('access');
  localStorage.removeItem('refresh');
  // Additional logic for redirecting to login or similar
};


export const login = (email, password) => async (dispatch) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken(),
      },
    };
    // console.log('login,',email,password)
    const body = JSON.stringify({ email, password });
    // console.log('log in',body)

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config);


    dispatch({
      type: LOGIN_SUCCESS,
      payload: response.data,
    });

    dispatch(load_user());

    return response;
  } catch (error) {
    dispatch({ type: LOGIN_FAIL });
    throw error;
  }
};




export const signup = signupData => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `JWT ${localStorage.getItem('access')}`,
    }
  };

  // Assuming 'password' needs to be part of the request, you need to include it in the parameters
  const body = JSON.stringify(signupData);

  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);

    dispatch({
      type: SIGNUP_SUCCESS,
      payload: res.data
    });

    return res;
  } catch (err) {
    console.error('Signup Error: ', err.response.data);

    dispatch({
      payload: err.response.data
    });

    throw err;
  }
};





export const reset_password = (email, onSuccess, onError) => async (dispatch) => {
    const config = {
        headers: { 'Content-Type': 'application/json' }
    };

    const body = JSON.stringify({ email });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config);

        dispatch({ type: PASSWORD_RESET_SUCCESS });
        if (onSuccess) onSuccess();
    } catch (err) {
        dispatch({ type: PASSWORD_RESET_FAIL });
        if (onError) onError();
    }
};



export const reset_password_confirm = (uid, token, new_password, re_new_password) => async dispatch => {
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ uid, token, new_password, re_new_password });

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config);
    if (response.status === 204) {
      dispatch({
        type: PASSWORD_RESET_CONFIRM_SUCCESS
      });
      // console.log(response, 'response2');
      return { success: true, data: response.data };
    }
  } catch (err) {
    console.error('Reset password confirm caught error:', err.response.data);

    dispatch({
      type: PASSWORD_RESET_CONFIRM_FAIL
    });
    // Returning an object indicating success as false and including the error details
    return { success: false, error: err.response.data };
  }
};




export const logout = () => dispatch => {
  dispatch({
    type: LOGOUT
  });
};
