import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Link, SvgIcon } from '@mui/material';
import Header from '../../components/Header';
// import Footer2 from '../global/Footer';
import releaseNotesData from './notes.json';
import BugReportIcon from '@mui/icons-material/BugReport'; // If you're using MUI icons
import { Typewriter } from 'react-simple-typewriter';

const ReleaseNotes = () => {
    const [noteRefs, setNoteRefs] = useState([]);
    const people = ['Laura Zhang', 'Daniel Chen', 'Steven Jin', 'Henry Zhao'];

    useEffect(() => {
        setNoteRefs(releaseNotesData.map(() => React.createRef()));
    }, []);

    return (
        <Container sx={{ margin: '20px', paddingBottom: '4vh' }}>
            <Header title="LIGHTEN TEST - Release Notes" />
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Link 
      href="https://docs.google.com/forms/d/e/1FAIpQLSfLssMK1KzgCfrLO9f-lccg_PZ08L2qnotZuWuYIRZrXCgoow/viewform?usp=sf_link" 
      target="_blank" 
      color="inherit" 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        textDecoration: 'none', 
        padding: '10px 20px', 
        borderRadius: '8px', 
        border: '1px solid #ccc', 
        transition: 'background-color 0.3s', 
        '&:hover': { backgroundColor: '#f0f0f0' } 
      }}
    >
      <BugReportIcon sx={{ mr: 1 }} />
      Report a Bug
    </Link>

    <Typography variant="h6" component="div" sx={{ textAlign: 'center', mt: 2 }}>
      Thank you,{' '}
      <Typewriter
              words={people}
              loop={0}
              cursor
              cursorStyle='_'
              typeSpeed={200}
              deleteSpeed={100}
              delaySpeed={1000}
            />
      <br />
      Your feedback helps us improve!
    </Typography>
  </Box>
            <Box sx={{ flex: 1, pr: 2 }}>
                {releaseNotesData.map((note, index) => (
                    <Box key={index} ref={noteRefs[index]}>
                        <Typography variant="h5" component="h2" color="primary" gutterBottom style={{ fontSize: '1.25rem', fontWeight: 'bold' }}>
                            {note.date}
                        </Typography>
                        {note.updates.map((update, idx) => (
                            <Typography key={idx} variant="body1" paragraph style={{ fontSize: '1rem' }}>
                                - {update}
                            </Typography>
                        ))}
                    </Box>
                ))}
            </Box>
            
        </Container>
    );
};

export default ReleaseNotes;
