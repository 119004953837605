import {
    Typography,
    Grid,
    Paper,Link as MuiLink,
  } from '@mui/material';
  import DOMPurify from 'dompurify';
  import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const choiceToLetter = (index) => ['A', 'B', 'C', 'D'][index];
export const renderChoicesInGrid = (choices, correctChoiceIndex) => {
    return choices.map((choice, index) => (
      <Grid item xs={6} key={index}>
        <Paper
          elevation={2}
          style={{
            padding: '10px',
            margin: '10px',
            display: 'flex',
            alignItems: 'center',
            borderColor: index === correctChoiceIndex ? 'green' : 'transparent', // Add border color conditionally
            borderWidth: '2px',
            borderStyle: 'solid',
          }}
        >
        <Typography variant="body1" gutterBottom style={{ fontSize: '1.1rem' }}> {/* Adjust font size as needed */}
  {choiceToLetter(index)}: {' '}
  <span className="rich-text-content2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(choice) }} />
</Typography>

          {index === correctChoiceIndex && (
            <CheckCircleOutlineIcon style={{ color: 'green', marginLeft: '10px' }} />
          )}
        </Paper>
      </Grid>
    ));
  };