import React, { useState, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Divider,
  Stack,
  Chip,CardHeader,
} from '@mui/material';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';

import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { startExams } from '../actions/auth';
import LoadingOverlay from './LoadingOverlay';
const MyExams = ({ exam }) => {
  const navigate = useNavigate();
  const currentTime = new Date();
  const startTime = new Date(exam.exam_schedule.start_time);
  const endTime = new Date(exam.exam_schedule.end_time);
  const isReady = useMemo(() => endTime >= currentTime && exam.status === 'B', [startTime, endTime, currentTime, exam.status]);
  const isPastStartButNotStarted = useMemo(() => endTime < currentTime && exam.status === 'B', [endTime, currentTime, exam.status]);
  const isOngoing = useMemo(() => exam.status === 'P', [exam.status]);
  const isExamTime = useMemo(() => {
    const now = new Date();
    return now >= startTime ;
  }, [startTime, endTime]);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Add loading state here if it's not managed by a parent component

  const formatDateTime = (date) => {
    return date.toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      // year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const formatCreatedAt = (createdAt) => {
    return createdAt ? formatDateTime(new Date(createdAt)) : "Unknown";
  };

  const handleStartExam = useCallback(() => {
    if (isReady || isOngoing) {
      setConfirmationOpen(true);
    }
  }, [isReady, isOngoing]);



  const handleConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, []);

  const confirmStartExam = useCallback(async () => {
    try {
      setLoading(true); // Start loading before the async operation
      const examData = await startExams(exam.id);
      if (examData) {
        navigate(`/testpage/${examData.id}`,{ state: { from: 'myexam' } });
      }
    } catch (error) {
      console.error('Error starting exam:', error);
    } finally {
      setLoading(false); // Stop loading after the async operation completes or fails
    }
    setConfirmationOpen(false);
  }, [exam.id, navigate]);

  const duration = exam.exam_schedule.schedule_type !== 'Full' ? exam.exam_schedule.duration / 2 : exam.exam_schedule.duration;
  if (loading) {
    return (

<LoadingOverlay/>
    );
}
  return (
    <Grid item xs={12} sm={6} md={4}>
                <Card sx={{ maxWidth: 345, width: '100%' }}>
                <CardHeader
      sx={{
        paddingBottom: '0px',
        '.MuiCardHeader-title': {
          fontWeight: 'bold', // makes title more prominent
          color: '#333' // darker color for better readability
        },
        '.MuiCardHeader-subheader': {
          margin: '8px 0 0', // adjust subheader margin for better visual alignment
        },
        '.MuiChip-root': {
          border: 'none', // removes the border from the Chip for a cleaner look
        }
      }}

      title={`${exam.exam_schedule.exam_name} - ${exam.exam_schedule.schedule_type}`}
      subheader={
        <Chip 
          icon={<EventOutlinedIcon />}
          label={`${formatDateTime(startTime)} - ${formatDateTime(endTime)}`}
          color="info"
          variant="outlined"
          sx={{
            fontSize: '0.875rem', // smaller font size for the Chip label
            fontWeight: 'medium' // medium weight for the Chip label
          }}
        />
      }
    />
        <CardContent>
         
        <Divider sx={{ mt: 0, mb: 2 }} />

      

  
          <Typography color="textSecondary" gutterBottom>
            Duration: {`${duration} minutes`}
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Created on: {formatCreatedAt(exam.exam_schedule.created_at)}
          </Typography>

          <Stack spacing={2} direction="column" mt={2}>
            {isReady && !isExamTime && (
              <Typography variant="body2" color="error">
                The exam can only be started during the scheduled time.
              </Typography>
            )}
            {isExamTime && (
              <Button variant="contained" color="primary" onClick={handleStartExam}>
                {isOngoing ? 'Continue Exam' : 'Start Exam'}
                
              </Button>
            )}
            {!isExamTime && isReady && (
               <Button 
                variant="outlined" 
                startIcon={<EventAvailableIcon />}
                disabled
                sx={{
                  color: 'grey.500', 
                  borderColor: 'grey.500',
                  '& .MuiButton-startIcon': {
                    color: 'grey.500',
                  }
                }}
              >
                Waiting for scheduled start time
              </Button>
            
            )}
            {isPastStartButNotStarted && (
              <Button variant="contained" disabled>
                Exam Window Closed
              </Button>
            )}
          </Stack>
        </CardContent>
      </Card>
      <Dialog open={confirmationOpen} onClose={handleConfirmationClose}>
        <DialogTitle>{isOngoing ? 'Continue Exam' : 'Start Exam'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {isOngoing ? 'continue' : 'start'} the exam?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmStartExam} color="primary" autoFocus>
            {isOngoing ? 'Continue' : 'Start'}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default React.memo(MyExams);
