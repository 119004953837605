import React from 'react';
import { Routes, Route } from "react-router-dom";
import Instruction from '../scenes/instruction';
import TestPage from '../scenes/testpage';
const NonColoredRoute = () => {
  return (
    <Routes>
      <Route path="/instruction" element={<Instruction />} />
      <Route path="/testpage" element={<TestPage/>}/>
    </Routes>
  );
};

export default NonColoredRoute;
