import { FETCH_SCHEDULED_EXAMS_SUCCESS,FETCH_EXAMS_SUCCESS, FETCH_EXAM_DETAILS_SUCCESS,CREATE_EXAM_SUCCESS} from '../actions/types';


const initialState = {
  scheduled: [],
  allexams: [],
  singleexam: {},
  newexam: {},
};

const scheduledExamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHEDULED_EXAMS_SUCCESS:
      return {
        ...state,
        scheduled: action.payload,
      };
    case FETCH_EXAMS_SUCCESS:
      return {
        ...state,
        allexams: action.payload,
     };
     case FETCH_EXAM_DETAILS_SUCCESS:
      return {
        ...state,
        singleexam: action.payload,
     };
     case CREATE_EXAM_SUCCESS:
      return {
        ...state,
        newexam: action.payload,
     };
     
    default:
      return state;
  }
};


export default scheduledExamsReducer;
