
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Bar from "./scenes/bar";
// import FAQ from "./scenes/faq";
import Calendar from "./scenes/calendar/calendar";
// import Login from './scenes/login/Login';
import Login from './scenes/Login';
import { Provider } from 'react-redux';
import store from './store';
import PrivateRoute from "./utils/PrivateRoute";
import PublicRoute from "./utils/PublicRoute";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Layout from "./hocs/Layout";
import NotFound from "./scenes/notfound/Notfound";
import ResesetPassword from "./scenes/resetpassword/ResetPassword";
import ResetPasswordConfirm from "./scenes/resetpasswordconfirm/ResetPasswordConfirm";
import axios from 'axios';
import NonColoredRoute from './utils/NonColoredRoute'; // import the new component
import TestPage from './scenes/testpage';
import Myexams from './scenes/myexams';
import ManageExamList from './scenes/manageexams';
import ViewExamDetails from './scenes/manageviewexam';
import Testpagepreview from './scenes/testpagepreview';
import ExamDetails from './components/Viewdetails';
import CongratulationsPage from './scenes/congrad';
import setupInterceptors from './actions/auth';
import Schedulelist from './scenes/scheduleList';
import StudentExamList from './scenes/studentexam';
import ReleaseNotes from './scenes/releaseNotes';
// import Footer2 from './scenes/global/Footer';
import OneStudent from './scenes/oneStudent';
import QuestionBank from './scenes/questionbank';
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();

  const sidebarPaths = ["/", "/team", "/bar", "/faq","/schedules", "/calendar", "/my-exams", "/manage-exams", "/viewexamdetails/:examId","/schedule-details","/release-notes"];
  const shouldShowSidebar = (pathname) => {
    return sidebarPaths.some(path => 
      pathname === path || 
      (path.includes(':') && pathname.startsWith(path.split('/:')[0]))
    );
  };
  useEffect(() => {
    setupInterceptors(store.dispatch); // Setup interceptors only once
    fetchAndStoreCsrfToken(); // Fetch CSRF token on mount
  }, []);
  const isSidebarVisible = shouldShowSidebar(location.pathname) && location.pathname !== "/login";


  const nonColoredPaths = ["/instruction"];

  const isNonColoredRoute = nonColoredPaths.includes(location.pathname);

  return (
    <Provider store={store}>
      {isNonColoredRoute ? (
        <NonColoredRoute />
      ) : (
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <div className="app">
              {(sidebarPaths.includes(location.pathname) && location.pathname !== "/login") && <Sidebar isSidebar={isSidebar} />}

              <main className="content">
                {/* <Topbar setIsSidebar={setIsSidebar} /> */}
                <Layout>
                  <Routes>
                    <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
                    <Route path="/team" element={<PrivateRoute><Team /></PrivateRoute>} />
                    <Route path="/manage-exams" element={<PrivateRoute><ManageExamList /></PrivateRoute>} />
                    <Route path="/satsuitequestionbank" element={<PrivateRoute><QuestionBank /></PrivateRoute>} />
                    <Route path="/schedules" element={<PrivateRoute><Schedulelist /></PrivateRoute>} />
                    
                    <Route path="/viewexamdetails/:examId" element={<PrivateRoute><ViewExamDetails /></PrivateRoute>} />

                    {/* <Route path="/EditExamDetails/:examId" element={<PrivateRoute><EditExam /></PrivateRoute>} /> */}
                    <Route path="/testpage/:studentexamId" element={<PrivateRoute><TestPage /></PrivateRoute>} />
                    <Route path="/previewexam/:examId" element={<PrivateRoute><Testpagepreview/></PrivateRoute>} />
                    <Route path="/schedule-details/:scheduleId" element={<PrivateRoute><StudentExamList/></PrivateRoute>} />
                    <Route path="/students/:studentId/exams" element={<PrivateRoute><OneStudent/></PrivateRoute>} />

                    <Route path="/exam-details/:examId" element={<PrivateRoute><ExamDetails /></PrivateRoute>} />
                    Scores
                    <Route path="/completed/:studentexamId" element={<PrivateRoute><CongratulationsPage /></PrivateRoute>} />
                    
                    <Route path="/release-notes" element={<PrivateRoute><ReleaseNotes /></PrivateRoute>} />

                    <Route path="/my-exams" element={<PrivateRoute><Myexams /></PrivateRoute>} />
                    <Route path="/bar" element={<PrivateRoute><Bar /></PrivateRoute>} />
                    {/* <Route path="/faq" element={<PrivateRoute><FAQ /></PrivateRoute>} /> */}
                    <Route path="/calendar" element={<PrivateRoute><Calendar /></PrivateRoute>} />
                    <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                    <Route path="/reset-password" element={<PublicRoute><ResesetPassword /></PublicRoute>} />
                    <Route path="/password/reset/confirm/:uid/:token" element={<ResetPasswordConfirm />} />
                    <Route path="*" element={<NotFound />} />
                  </Routes>
                </Layout>

              </main>
            {/* <Footer2/> */}

            </div>

          </ThemeProvider>
        </ColorModeContext.Provider>
      )}
    </Provider>
  );
}

const fetchAndStoreCsrfToken = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/user/get-csrf-token`);
    const csrfToken = response.data.csrfToken;
    localStorage.setItem('csrfToken', csrfToken); // Storing the token in local storage
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};

export default App;
