import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { checkAuthenticated, load_user } from '../actions/auth';
import { Box, CircularProgress } from '@mui/material'; // 引入Box和CircularProgress
import './Layout.css'
const Layout = ({ checkAuthenticated, load_user, children }) => {
    const [isCheckingAuthStatus, setIsCheckingAuthStatus] = useState(true);

    useEffect(() => {
        const checkAuthStatus = async () => {
            await checkAuthenticated();
            await load_user();
            setIsCheckingAuthStatus(false);
        };

        checkAuthStatus();
    }, []);

    if (isCheckingAuthStatus) {
        return (
            <Box className="loading-overlay" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box>
        ); // 使用你的加载指示器
    }

    return (
        <div>
            {children}
        </div>
    );
};

export default connect(null, { checkAuthenticated, load_user })(Layout);
