import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { listscheduleExam } from '../../actions/auth';
import { Grid, Typography, Box, Container, FormControl, InputLabel } from '@mui/material';
import ScheduleCard from './ScheduleCard';
import Header from '../../components/Header';
import LoadingOverlay from '../../components/LoadingOverlay';
import Pagination from '@mui/material/Pagination'; // Import MUI Pagination

const Schedulelist = () => {
    const dispatch = useDispatch();
    const { scheduled, error, next, previous } = useSelector(state => state.scheduledExams);
    const count = scheduled.count ? scheduled.count : 0;
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(30); 
    const [loading, setLoading] = useState(true);

    const refreshSchedules = () => {
        dispatch(listscheduleExam()); // Refetch schedules
    };


    useEffect(() => {
        dispatch(listscheduleExam(currentPage))
            .then(() => setLoading(false)) // Set loading to false when the data is fetched
            .catch(() => setLoading(false)); // Ensure loading is set to false even if there's an error
    }, [dispatch, currentPage]);

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <Typography color="error">Error: {error}</Typography>;
    }

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'  // Smoothly scroll to the top
        });
    };

    const groupedExams = scheduled.results ? scheduled.results
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) // Sort exams by created_at (most recent first)
        .reduce((acc, exam) => {
            const monthYear = new Date(exam.start_time).toLocaleString('en-US', { month: 'long', year: 'numeric' });
            if (!acc[monthYear]) {
                acc[monthYear] = [];
            }
            acc[monthYear].push(exam);
            return acc;
        }, {}) : {};

    const countTodayExams = () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to 00:00:00.000

        return scheduled.results.filter(exam => {
            const examDate = new Date(exam.created_at);
            examDate.setHours(0, 0, 0, 0); // Normalize time for comparison
            return examDate.getTime() === today.getTime();
        }).length;
    };

    const totalTodayExams = countTodayExams();

    return (
        <Container sx={{ margin: '20px' }}>
            <Header title="SCHEDULED EXAMS" subtitle={`Total Scheduled for Today: ${totalTodayExams}`} page='scheduleList' />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <FormControl variant="outlined" size="small">
                    <InputLabel id="page-size-label">Page Size</InputLabel>
                </FormControl>
            </Box>
            {Object.entries(groupedExams).map(([monthYear, exams]) => (
                <Box key={monthYear} mb={4}>
                    <Typography sx={{ fontWeight: 'bold' }} variant="h3" gutterBottom>{monthYear}</Typography>
                    <Grid container spacing={3}>
                        {exams.map(exam => (
                            <ScheduleCard key={exam.id} exam={exam} onDeleted={refreshSchedules} />
                        ))}
                    </Grid>
                </Box>
            ))}
            <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                <Typography variant="body2" sx={{ mr: 1 }}>
                    Page {currentPage} of {Math.ceil(count / pageSize)}
                </Typography>
                <Pagination
                    count={Math.ceil(count / pageSize)}
                    page={currentPage}
                    siblingCount={1}
                    boundaryCount={1}
                    onChange={handlePageChange}
                    showFirstButton
                    showLastButton
                    sx={{
                        '& .MuiPaginationItem-root': {
                            '&:focus': {
                                outline: 'none',
                            },
                        }
                    }}
                />
            </Box>
        </Container>
    );
};

export default Schedulelist;
