import React, { useState } from 'react';
import { Container, Typography, Box, Button, Select, MenuItem, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox, Paper, Grid } from '@mui/material';

const QuestionBank = () => {
    const [editTest, setEditTest] = useState(true); // Controls whether the test selection is editable
    const [test, setTest] = useState(''); // Holds the selected test type
    const [domainScores, setDomainScores] = useState([]); // Holds domain scores based on the selected test

    const handleTestChange = (event) => {
        setTest(event.target.value);
        updateDomainScores(event.target.value);
        setEditTest(false); // Close the dropdown after selection
    };

    const handleToggleTestEdit = () => {
        if (editTest && test === '') { // Reset domain scores only if editing and no test selected yet
            setDomainScores([]);
        }
        setEditTest(!editTest); // Toggle the edit state
    };

    const updateDomainScores = (testType) => {
        // Define domain scores based on the test type
        if (testType === 'Math') {
            setDomainScores(['Algebra', 'Advanced Math', 'Problem-Solving and Data Analysis', 'Geometry and Trigonometry']);
        } else if (testType === 'Reading and Writing') {
            setDomainScores(['Information and Ideas', 'Craft and Structure', 'Expression of Ideas', 'Standard English Conventions']);
        }
    };

    return (
        <Container component={Paper} elevation={3} sx={{ p: 4, marginTop: 4, borderRadius: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom>EDUCATOR QUESTION BANK</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Assessment: SAT</Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" gutterBottom>
                        Test:
                        {editTest ? (
                            <FormControl fullWidth>
                                <InputLabel id="test-select-label">Select a section</InputLabel>
                                <Select
                                    labelId="test-select-label"
                                    id="test-select"
                                    value={test}
                                    label="Select a section"
                                    onChange={handleTestChange}

                                >

                                    <MenuItem value="Math">Math</MenuItem>
                                    <MenuItem value="Reading and Writing">Reading and Writing</MenuItem>
                                </Select>
                            </FormControl>
                        ) : (
                            <>
                                {test ? test : ""}
                                <Button variant="text" size="small" onClick={handleToggleTestEdit} sx={{ ml: 2 }}>
                                    {test ? "Change" : ""}
                                </Button>
                            </>
                        )}
                    </Typography>
                </Grid>
                {test && !editTest && (
                    <Grid item xs={12}>
                        <FormGroup>
                            <Typography variant="subtitle1" gutterBottom>Find questions by Domain Scores:</Typography>
                            {domainScores.map((score, index) => (
                                <FormControlLabel key={index} control={<Checkbox />} label={score} />
                            ))}
                        </FormGroup>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', pt: 2 }}>
                        <Button variant="outlined" color="error">Cancel</Button>
                        <Button variant="contained" color="primary">Search</Button>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default QuestionBank;
