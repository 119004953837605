import React,{useCallback,useState,useRef,useEffect} from 'react';
import styles from './BreakPage.module.css'; // Import as a module
import '../scenes/css/bootstrap45.min.css'; // Assuming this is global and necessary
import CircularProgress from '@mui/material/CircularProgress';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';


const BreakPage = ({ breakendTime, onSkipBreak,user }) => {
    // const theme = useTheme(); // Use the theme for consistent styling
    const calculateTimeLeft = useCallback(() => {
      const now = new Date().getTime();
      const distance = new Date(breakendTime).getTime() - now;
      return Math.max(0, Math.ceil(distance / 1000));
  }, [breakendTime]);

  const [time, setTime] = useState(calculateTimeLeft());
  const [loading, setLoading] = useState(true);
  const intervalRef = useRef(null);
  const [hide, setHide] = useState(false);

  const startTimer = useCallback(() => {
      if (intervalRef.current !== null) {
          clearInterval(intervalRef.current);
      }

      intervalRef.current = setInterval(() => {
          setTime((prevTime) => {
              const newTime = calculateTimeLeft();
              if (newTime <= 0) {
                  clearInterval(intervalRef.current);
                  if (onSkipBreak) onSkipBreak();
                  return 0;
              }
              return newTime;
          });
          setLoading(false); // Stop loading once the timer is initialized
      }, 1000);
  }, [calculateTimeLeft, onSkipBreak]);

  useEffect(() => {
      startTimer();

      return () => {
          if (intervalRef.current !== null) {
              clearInterval(intervalRef.current);
          }
      };
  }, [startTimer]);

  useEffect(() => {
      setTime(calculateTimeLeft());
  }, [breakendTime, calculateTimeLeft]);

  const formatTime = () => {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}:${String(seconds).padStart(2, '0')}`;
  };
    return (
        <div id="app" className={styles.app}>
          <div className={styles.break}>
            <div className={styles['left-side']}>
              <div className={styles.timer}>
                <span className={styles['timer-title']}>Remaining Break Time:</span>
                {/* Format breakDuration directly */}


                {loading ? (
                    <CircularProgress size={65} color='secondary'></CircularProgress>
                ) : !hide ? (
                    <span className={styles['timer-time']} id="timer">{formatTime()}</span>

                ) : (
                    <span className="visually-hidden"><TimerOutlinedIcon fontSize="large" /></span>
                )}



              </div>
              {/* Button to resume testing */}
              <div className={styles['resume-btn']} onClick={onSkipBreak}>Resume Testing</div>
            </div>
            <div className={styles['right-side']}>
              <div className={styles.contents}>
                <span className={styles['desc-title']}>Practice Test Break</span>
                <span className={styles.desc}>You can resume this practice test as soon as you're ready to move on. On test day, you'll wait until the clock counts down. Read below to see how breaks work on test day.</span>
                <hr />
                <span className={styles['desc-title']}>Take a Break: Do Not Close Your Browser</span>
                <span className={styles.desc}>You may leave the room, but do not disturb students who are still testing.</span>
                <span className={styles.desc}>Do not exit the app or close your device.</span>
                <span className={styles.desc}>Testing won't resume until you return.</span>
                <span className={styles.desc}><strong>Follow these rules during the break:</strong>
                  <ol>
                    <li>Do not access your phone, smartwatch, textbooks, notes, or the internet.</li>
                    <li>Do not eat or drink in the test room.</li>
                    <li>Do not speak in the test room; outside the test room, do not discuss the exam with anyone.</li>
                  </ol>
                </span>
              </div>
            </div>
          </div>
          <div className={styles.footer}><span className={styles['user-name']}>{user.first_name} {user.last_name}</span></div>


        </div>
      );
};

export default BreakPage;