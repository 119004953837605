import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { reset_password } from '../../actions/auth';
import { Button } from '@mui/material';
import br from './logo.svg';
import LoadingOverlay from '../../components/LoadingOverlay';

const ResetPassword = ({ reset_password }) => {
  const [formData, setFormData] = useState({
    email: '',
  });
  const [loading, setLoading] = useState(false); // State to control loading overlay
  const navigate = useNavigate();

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Show loading overlay

    try {
      await reset_password(email); // Assuming this returns a Promise
      navigate('/login', { state: { resetEmailSent: true } });
    } catch (error) {
      console.error('Error resetting password:', error);
    } finally {
      setLoading(false); // Hide loading overlay after operation completes
    }
  };

  const handleReturnToLogin = () => {
    navigate('/login');
  };
  if (loading) {
    return (

<LoadingOverlay/>
    );
}

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img className="w-8 h-8 mr-2" src={br} alt="logo" />
            LIGHTEN ACADEMY
          </a>
          <div className="w-full p-6 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md dark:bg-gray-800 dark:border-gray-700 sm:p-8">
            <h1 className="mb-1 text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Forgot your password?
            </h1>
            <p className="font-light text-gray-500 dark:text-gray-400">
              Don't worry, you'll remember your password soon!
            </p>
            <form
              className="mt-4 space-y-4 lg:mt-5 md:space-y-5"
              onSubmit={onSubmit}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="example@mail.com"
                  required
                  value={email}
                  onChange={onChange}
                />
              </div>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  width: '100%',
                  padding: '10px 0',
                  marginBottom: '8px',
                  backgroundColor: 'primary.main',
                  '&:hover': {
                    backgroundColor: 'primary.dark',
                  },
                }}
              >
                Reset password
              </Button>
            </form>
            <Button
              variant="text"
              color="primary"
              onClick={handleReturnToLogin}
              style={{ marginTop: '10px' }}
              sx={{
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              Return to Login
            </Button>
          </div>
        </div>
      </section>
    </>
  );
};

export default connect(null, { reset_password })(ResetPassword);
