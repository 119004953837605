// FormDialog.js
import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Button, Box, TextField, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, CircularProgress, Snackbar, Alert } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { signup, reset_password } from '../../actions/auth';

const FormDialog = ({ open, handleClose,onActionFeedback }) => {
  const dispatch = useDispatch();

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      const signupData = {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        group_name: values.role
      };

      const signupResponse = await dispatch(signup(signupData));
      if (signupResponse) {
        await dispatch(reset_password(values.email));
        onActionFeedback(true, "Account created successfully! Check your email for password reset.");

        // Handle success
      } else {
        onActionFeedback(false, "Failed to set the password");

      }
    } catch (error) {
      onActionFeedback(false, "Error creating user. Please try again. (Maybe because the email already exists)");

      // Handle error
    } finally {
      setSubmitting(false);
      handleClose(); // Close the dialog after submission
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New User</DialogTitle>
      <DialogContent>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          role: "student",
        }}
        validationSchema={yup.object().shape({
          firstName: yup.string().required("Required"),
          lastName: yup.string().required("Required"),
          email: yup.string().email("Invalid email").required("Required"),
          role: yup.string().required("Required"),
        })}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
           
            >
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="First Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                name="firstName"
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Last Name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                name="lastName"
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                sx={{ gridColumn: "span 2" }}
              />
              <TextField
                fullWidth
                variant="filled"
                type="email"
                label="Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                name="email"
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                sx={{ gridColumn: "span 4" }}
              />
              <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                <FormLabel>Role</FormLabel>
                <RadioGroup
                  row
                  name="role"
                  value={values.role}
                  onChange={handleChange}
                >
                  <FormControlLabel value="student" control={<Radio />} label="Student" />
                  <FormControlLabel value="teacher" control={<Radio />} label="Teacher" />
                  <FormControlLabel value="staff" control={<Radio />} label="Staff" />

                </RadioGroup>
              </FormControl>
            </Box>
            <Box display="flex" justifyContent="end" mt="20px">
      <Button type="submit" color="secondary" variant="contained" disabled={isSubmitting}>
        {isSubmitting ? <CircularProgress size={24} /> : "Create New User"}
      </Button>
    </Box>
          </form>
        )}
      </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
