import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button, Container, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { AccessTime, Grade, Hearing, Lock } from '@mui/icons-material';

function Instruction({ examId }) {
  const navigate = useNavigate();

  const handleStartExam = () => {
    navigate(`/exam/${examId}/module/1`);
  };

  const handleGoBack = () => {
    navigate(-1); // Navigates back to the previous page
  };

  return (
    <Container maxWidth="sm">
      <Box my={4} textAlign="center">
        <Typography variant="h3" gutterBottom>
          Practice Test Instructions
        </Typography>

        <List>
          <ListItem>
            <ListItemIcon><AccessTime /></ListItemIcon>
            <ListItemText primary={<Typography variant="h5" gutterBottom>Timing</Typography>} 
                          secondary="Practice tests are timed, but you can pause them. To continue on another device, you have to start over. We delete incomplete practice tests after 90 days." />
          </ListItem>

          <ListItem>
            <ListItemIcon><Grade /></ListItemIcon>
            <ListItemText primary={<Typography variant="h5" gutterBottom>Scores</Typography>} 
                          secondary="When you finish the practice test, go to My Practice to see your scores and get personalized study tips." />
          </ListItem>

          <ListItem>
            <ListItemIcon><Hearing /></ListItemIcon>
            <ListItemText primary={<Typography variant="h5" gutterBottom>Assistive Technology</Typography>} 
                          secondary="If you use assistive technology, try it out on the practice test so you know what to expect on test day." />
          </ListItem>

          <ListItem>
            <ListItemIcon><Lock /></ListItemIcon>
            <ListItemText primary={<Typography variant="h5" gutterBottom>No Device Lock</Typography>} 
                          secondary="We don’t lock your device during practice. On test day, you'll be blocked from accessing other programs or apps." />
          </ListItem>
        </List>

        <Box mt={4}>
          <Button variant="contained" onClick={handleGoBack}>
            Back
          </Button>
          <Button variant="contained" color="primary" style={{ marginLeft: '8px' }} onClick={handleStartExam}>
            Start Exam
          </Button>
        </Box>
      </Box>
    </Container>
  );
}

export default Instruction;
