import React, { useState, useEffect, useRef, useCallback } from 'react';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import CircularProgress from '@mui/material/CircularProgress';
const Countdown1 = React.memo(({ endTime, onTimeout }) => {
    // console.log('endtime',endTime)
    const calculateTimeLeft = useCallback(() => {
        const now = new Date().getTime();
        const distance = new Date(endTime).getTime() - now;
        return Math.max(0, Math.ceil(distance / 1000));
    }, [endTime]);

    const [time, setTime] = useState(calculateTimeLeft());
    const [loading, setLoading] = useState(true);
    const intervalRef = useRef(null);
    const [hide, setHide] = useState(false);

    const startTimer = useCallback(() => {
        if (intervalRef.current !== null) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            setTime((prevTime) => {
                const newTime = calculateTimeLeft();
                if (newTime <= 0) {
                    clearInterval(intervalRef.current);
                    if (onTimeout) onTimeout();
                    return 0;
                }
                return newTime;
            });
            setLoading(false); // Stop loading once the timer is initialized
        }, 1000);
    }, [calculateTimeLeft, onTimeout]);

    useEffect(() => {
        startTimer();

        return () => {
            if (intervalRef.current !== null) {
                clearInterval(intervalRef.current);
            }
        };
    }, [startTimer]);

    useEffect(() => {
        setTime(calculateTimeLeft());
    }, [endTime, calculateTimeLeft]);

    const formatTime = () => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${String(seconds).padStart(2, '0')}`;
    };

    const toggleHide = () => {
        setHide((prevHide) => !prevHide);
    };

    return (
        <div className="mid-topbar col-md-4">
            <div className="timer">
                <i className="bi-stopwatch" id="timerx"></i>
                {loading ? (
                    <CircularProgress size={25}></CircularProgress>
                ) : !hide ? (
                    <span id="timer">{formatTime()}</span>
                ) : (
                    <span className="visually-hidden"><TimerOutlinedIcon fontSize="large" /></span>
                )}
            </div>
            <div>
                <span className="header-desc btn" id="hidetimer" onClick={toggleHide}>
                    {hide ? 'Show' : 'Hide'}
                </span>
            </div>
        </div>
    );
});

export default Countdown1;
