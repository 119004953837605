import React from 'react';
import { Link } from "react-router-dom";
import { Box, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme"; // Ensure this path is correct

function NotFound() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    // Determine button color based on theme mode
    const buttonStyle = theme.palette.mode === 'dark' ? 
        {
            backgroundColor: colors.primary[100], // For dark theme
            color: colors.grey[900], // Text color for dark theme
        } : 
        {
            backgroundColor: colors.primary[100], // For light theme
            color: colors.grey[900], // Text color for light theme
        };

    return (
        <Box 
            id="notfound" 
            style={{ 
                backgroundColor: theme.palette.background.default, 
                color: theme.palette.text.primary,
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: '100vh' 
            }}
        >
            <Typography variant="h1" component="h2" gutterBottom>
                404 - The Page can't be found
            </Typography>
            <Button 
                component={Link} 
                to='/login' 
                style={{ 
                    marginTop: '20px',
                    ...buttonStyle
                }}
                variant="contained"
            >
                Go To Homepage
            </Button>
        </Box>
    );
}

export default NotFound;
