import React from 'react';

const getSectionName = (order) => {
  const sectionNames = {
    'Reading and Writing 1': 'Section 1, Module 1: Reading and Writing',
    'Reading and Writing 2': 'Section 1, Module 2: Reading and Writing',
    'Math 1': 'Section 2, Module 1: Math',
    'Math 2': 'Section 2, Module 2: Math',
  };

  return sectionNames[order] || 'Unknown Section';
};

// Accept elementType and className as additional props
const SectionDisplay = ({ order, elementType = 'div', className = 'header-title test-title' }) => {
  // Determine the Element type dynamically
  const Element = elementType;

  return (
    <Element className={className}>
      {getSectionName(order)}
    </Element>
  );
};

export default SectionDisplay;
