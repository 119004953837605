import React,{useState} from 'react';
import { TextField, Box, Tooltip,useTheme, Badge,Typography,LinearProgress } from '@mui/material';
import DOMPurify from 'dompurify';


const MultipleChoiceQuestion = React.memo(({
    question, options, selectedValue = '',  onValueChange = () => {}, questionNumber, isMCQuestion, onBlur, readOnly,markedForReview, onMarkForReview,crossedOut=[],toggleCrossedOut,toggleTrueCrossedOut,crossIcon,toggleCrossIcon,statistics=[]

}) => {
  const theme = useTheme();

  const handleChange = (event,index) => {
    onValueChange(event);
    toggleTrueCrossedOut(index); // Toggle the crossed-out state for this index

  };
  const processHtmlContent = (html) => {
    return html.replace(/<p>/gi, '<span>').replace(/<\/p>/gi, '</span>');
  };
  const calculateOverallCorrectPercentage = (statistics, correctChoice) => {
    // console.log(statistics,'and',selectedValue);
    const totalResponses = statistics.reduce((acc, stat) => acc + stat.count, 0);
    const correctResponses = statistics
      .filter(stat => parseInt(stat.schoice) === correctChoice)
      .reduce((acc, stat) => acc + stat.count, 0);
    // console.log('correctResponses', correctResponses);
    return totalResponses > 0 ? (correctResponses / totalResponses) * 100 : 0;
  };
  
  
  const overallCorrectPercentage = calculateOverallCorrectPercentage(statistics,parseInt(selectedValue));
  const getColor = (percentage) => {
    if (percentage >= 75) {
      return theme.palette.success.main; // Green for high scores
    } else if (percentage >= 50) {
      return theme.palette.warning.main; // Yellow for medium scores
    } else {
      return theme.palette.error.main; // Red for low scores
    }
  };
  return (
    <div className="page-content">
       <Box mb={2}>
        <Typography variant="h6">
          Overall Correct Percentage: {overallCorrectPercentage.toFixed(2)}%
        </Typography>
        
        <LinearProgress
          variant="determinate"
          value={overallCorrectPercentage}
          sx={{
            '& .MuiLinearProgress-bar': {
              backgroundColor: getColor(overallCorrectPercentage),
            },
            height: '6px',
          }}
        />      </Box>
      <div className="question-title">
      <div className="question-index">{questionNumber}</div>
      <div onClick={onMarkForReview} className={`bookmark ${markedForReview ? 'filled' : ''}`}>
            Mark for Review
        </div>
             {isMCQuestion ? (<div onClick={toggleCrossIcon} className={`crossing-options ${crossIcon ? 'filled' : ''}`}></div>):(<></>)}
    </div>
    <div className="q-dash"></div>

   
    <div className="question-content" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processHtmlContent(question)) }}/>
          {isMCQuestion ? (
        <div className="answer-zone">
         {options.map((option, index) => {
  const isSelected = selectedValue === String(index);
  const isWrong = !isSelected;
  const badgeColor = isSelected ? 'success' : (isWrong ? 'error' : 'default');
  const studentCount = statistics.find(stat => parseInt(stat.schoice) === index)?.count || 0;
  const correctness = isSelected ? 'Correct' : 'Incorrect';

  const tooltipTitle = `${studentCount} student${studentCount !== 1 ? 's' : ''} selected this option and marked it as ${correctness}. Data is from recent exam takers.`;

  return (
    <div className="option" key={index}>
      <input type="radio" name="quiz-options" id={`option-${index}`} value={String(index)} checked={selectedValue === String(index)} onChange={(event) => handleChange(event, index)} disabled={readOnly} />
      <label className={`select-zone ${crossedOut[index]&&crossIcon ? 'crossed':''} `} htmlFor={`option-${index}`}>
        {crossIcon && crossedOut[index] && <hr className="cross-line" />}
        <div className="option-label">{String.fromCharCode(65 + index)}</div>
        <span className="option-content">
          <div className="wrapper">
            <div className="display" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processHtmlContent(option)) }} />
          </div>
        </span>
      </label>
      <Box className="statistics" ml={2}>
      <Tooltip 
          title={tooltipTitle}
          arrow
        >                           <Badge badgeContent={studentCount} color={badgeColor}>

        </Badge>
        </Tooltip>
      </Box>
      <div className="crossing-zone" onClick={() => toggleCrossedOut(index)} style={{ display: crossIcon ? 'block' : 'none' }}>
        {crossedOut[index] ? (
          <span className="undo-cross">Undo</span>
        ) : (
          <>
            <div className="cross-label">{String.fromCharCode(65 + index)}</div>
            <hr className="cross-btn-line" />
          </>
        )}
      </div>
    </div>
  );
})}

        </div>
      ) : (
        <TextField
        variant="outlined"
        value={selectedValue}
        onChange={onValueChange}
        onBlur={onBlur}
        style={{ marginTop: '10px', width: '100px' }} // Set the width as desired
        autoComplete="off"
      />
      
      )}
    </div>
  );
});

export default MultipleChoiceQuestion;
